export const clearData = (
  object: { [k: string]: any },
  valueToDelete?: string
) => {
  for (const key in object) {
    if (object[key] === "" || !object[key] || object[key] === valueToDelete)
      delete object[key];
  }
  return object;
};

export const addQueryParamsToUrl = (
  url: string,
  values: { [k: string]: any }
) => {
  const entries = Object.entries(values);

  entries.forEach((entrie) => {
    if (url.indexOf("?") === -1) {
      url = url + "?" + entrie[0] + "=" + entrie[1];
    } else {
      url = url + "&" + entrie[0] + "=" + entrie[1];
    }
  });

  return url;
};
