import {
  Badge,
  Box,
  Button,
  Flex,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import moment from "moment";
import Select from "react-select";
import { ClasesContext } from "../../Context/clases/ClasesContext";
import { ClasesInterface } from "../../Interfaces/clasesInterface";
import { SessionContext } from "../../Context/session/SessionContext";
import { useClase } from "../../Hooks/Clases/useClase";

type Props = {
  clase: ClasesInterface;
};

export const Clase = ({ clase }: Props) => {
  const { session } = useContext(SessionContext);
  const { profesores } = useContext(ClasesContext);
  const { profesorSelected, estadoClase, onChangeProfesor, asignarProfesor } =
    useClase(clase);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const setProfesor = async () => {
    await asignarProfesor();
    onOpen();
  };

  return (
    <>
      <Box mt={5} backgroundColor={"white"} paddingX={10} paddingY={5}>
        <HStack justifyContent={"space-between"}>
          <HStack>
            {clase.estadoInicio.estado === "vivo" ? (
              <Badge colorScheme={"green"}>EN VIVO</Badge>
            ) : (
              ""
            )}
            <p>
              <b> {clase.curso.alias} </b> - {clase.nombreClase}
            </p>
            {clase.aulaAsignada && (
              <Badge colorScheme={"blue"}>{clase.aulaAsignada.nombre}</Badge>
            )}
          </HStack>
          <Stack direction={["column", "row"]} gap={2}>
            {clase.categoriasAlumnos.length ? (
              clase.categoriasAlumnos.map((categoria) => (
                <Badge colorScheme={"purple"}>{categoria.categoria}</Badge>
              ))
            ) : (
              <Badge>Sin categorias</Badge>
            )}
          </Stack>
        </HStack>
        <Stack
          direction={["column", "row"]}
          justifyContent={"space-between"}
          my={5}
        >
          <Text size={"sm"}>
            {clase.profesor.nombre} {clase.profesor.apellido}
          </Text>
          {moment(new Date(), "DD-MM-YYYY hh:mm:ss").isBefore(
            moment(`${clase.fecha} ${clase.horaFin}`, "DD-MM-YYYY hh:mm:ss")
          ) &&
          clase.curso.profesores.some(
            (profesor) =>
              profesor.value === session?.id && profesor.cargo === "1"
          ) ? (
            <Button colorScheme={"blue"} size="sm" onClick={onOpen}>
              Asignar profesor
            </Button>
          ) : null}
        </Stack>

        <SimpleGrid columns={[1, 2]} gap={[0, 6]}>
          <GridItem>
            <p>
              <b>Hora inicio:</b> {clase.horaInicio}
              {clase.timePais
                ? ` Arg -- ${clase.horaInicioFil} ${clase.timePais}`
                : null}
            </p>
            <p>
              <b>Hora finalización:</b> {clase.horaFin}
              {clase.timePais
                ? ` Arg -- ${clase.horaFinFil} ${clase.timePais}`
                : null}
            </p>
          </GridItem>
          <GridItem>
            <p>
              <b>Hora iniciada:</b> {clase.horaIniciadaFil}
              {clase.timePais
                ? ` Arg -- ${clase.horaInicioFil} ${clase.timePais}`
                : null}
            </p>
            <p>
              <b>Asistentes:</b> {clase.asistentes}{" "}
            </p>
          </GridItem>
        </SimpleGrid>

        <Text mt={5}>
          Coaches inician 15 minutos antes y staff 10 minutos antes
        </Text>

        <Flex justifyContent={"end"} alignItems={"center"} gap={2}>
          {estadoClase[clase.estadoInicio.estado]}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Asignar clase a profesor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              options={profesores}
              placeholder={"Seleccione un profesor"}
              onChange={(value) => onChangeProfesor(value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!profesorSelected.length}
              onClick={setProfesor}
              colorScheme={"blue"}
            >
              Seguro!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
