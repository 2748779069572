import { Button, ButtonGroup } from "@chakra-ui/react";
import { useModalMaterial } from "../../Hooks/Materiales/useModalMaterial";
import { ModalMaterialesProps } from "./ModalMaterial";

export const ModalDelete = ({
  idClase,
  material,
  idCursoAca,
  onClose,
}: ModalMaterialesProps) => {
  const { isLoading, deleteMaterial } = useModalMaterial({
    idClase,
    idCursoAca,
    material,
  });

  const handleButton = async () => {
    try {
      await deleteMaterial();
      if (onClose) onClose();
    } catch (error) {}
  };

  return (
    <ButtonGroup display={"flex"} justifyContent={"end"}>
      <Button colorScheme={"red"} isLoading={isLoading} onClick={handleButton}>
        Eliminar
      </Button>
    </ButtonGroup>
  );
};
