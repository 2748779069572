import { useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputLeftAddon,
  Flex,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { OrdenesContext } from "../../../../Context/docentes/ordenesDePago/ordenesContext";
import {
  CargaOrdenInterface,
  OrdenInterface,
} from "../../../../Interfaces/ordenesInterface";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

type Props = {
  orden: OrdenInterface;
};

export const Carga = ({ orden: { estado, periodo } }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [archivo, setArchivo] = useState<Blob>();
  const { tiposFacturas, addInvoice } = useContext(OrdenesContext);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const validation = Yup.object().shape({
    factura: Yup.number()
      .positive()
      .required("Campo requerido (solo números)")
      .min(1, "Ingrese un número valido (mayor a 0)"),
      
    importe: Yup.number()
      .required("Campo requerido (solo números)")
      .min(1, "Ingrese un número valido"),
    date: Yup.date().required("Campo requerido"),
  });

  const handleFileChange = ({ files }: EventTarget & HTMLInputElement) => {
    if (files) setArchivo(files[0]);
  };

  const handleSubmit = async (values: CargaOrdenInterface) => {
    if (!archivo) {
      return toast({
        title: "Error",
        description: "Seleccione un archivo válido",
        status: "error",
        isClosable: true,
      });
    }
    setLoading(true);

    try {
      await addInvoice(periodo, values, archivo);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: `${error}`,
        status: "error",
        isClosable: true,
      });
    }
  };
  
  return (
    <>
      <Button colorScheme={"green"} onClick={onOpen}>
        Subir Factura
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Factura</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                factura: "",
                date: "",
                tipo: "1",
                importe: "",
              }}
              validationSchema={validation}
              onSubmit={(values) => handleSubmit(values)}
              validateOnChange={true}
            >
              {({ errors }) => {
                return (
                  <Form>
                    <FormControl mb={5} isInvalid={Boolean(errors.factura)}>
                      <FormLabel>Numero de factura</FormLabel>
                      <Field
                        as={Input}
                        name={"factura"}
                        type={"number"}
                        isInvalid={errors.factura}
                      />
                      <FormErrorMessage>{errors.factura}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mb={5}
                      isInvalid={Boolean(errors.date?.length)}
                    >
                      <FormLabel>Fecha</FormLabel>
                      <Field as={Input} name={"date"} type={"date"} />
                      <FormErrorMessage>{errors.date}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb={5}>
                      <FormLabel>Tipo</FormLabel>
                      <Field
                        as={Select}
                        disabled={!tiposFacturas.length}
                        name={"tipo"}
                      >
                        {tiposFacturas?.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.tipo}
                          </option>
                        ))}
                      </Field>
                    </FormControl>
                    <FormControl
                      mb={5}
                      isInvalid={Boolean(errors.importe?.length)}
                    >
                      <FormLabel>Importe</FormLabel>
                      <InputGroup>
                        <InputLeftAddon children={"$"} />
                        <Field
                          as={Input}
                          name={"importe"}
                          type={"number"}
                          isInvalid={errors.importe}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.importe}</FormErrorMessage>
                    </FormControl>
                    <FormControl mb={5}>
                      <FormLabel>Archivo</FormLabel>
                      <Input
                        name={"archivo"}
                        type={"file"}
                        accept={"application/pdf, application/vnd.ms-excel"}
                        onChange={({ target }) => {
                          handleFileChange(target);
                        }}
                      />
                    </FormControl>

                    <Flex justifyContent={"end"} mt={5}>
                      <Button
                        colorScheme={"blue"}
                        disabled={Object.values(errors).length !== 0}
                        type={"submit"}
                        isLoading={loading}
                      >
                        Subir
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
