import { useContext } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { OrdenesContext } from "../../../Context/docentes/ordenesDePago/ordenesContext";
import { Buscador } from "./Buscador";
import { Generar } from "./Generar";

export const Ordenes = () => {
  const { tabSelected, isLoading, setTabIndex } = useContext(OrdenesContext);
  const tabs = [
    { name: "Buscar", component: <Buscador /> },
    { name: "Generar", component: <Generar /> },
  ];
  return (
    <Box maxW={"100vw"}>
      <Tabs
        variant={"soft-rounded"}
        colorScheme={"blue"}
        align={"center"}
        onChange={(index) => setTabIndex(index)}
        index={tabSelected}
      >
        <TabList backgroundColor={"white"} p={5}>
          {tabs.map((tab, key) => (
            <Tab isDisabled={isLoading} key={key}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab, key) => (
            <TabPanel key={key} p={0}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
