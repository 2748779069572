import React, { useContext } from "react";
import {
  Accordion,
  AccordionItem,
  Alert,
  AlertIcon,
  Heading,
} from "@chakra-ui/react";
import { MaterialesContext } from "../../Context/materiales/materialesContext";
import { MainBox } from "../Layouts/MainBox";
import { SpinnerAxn } from "../Layouts/SpinnerAxn";
import { MaterialesList } from "./MaterialesList";

export const AulasList = () => {
  const { aulas, isLoading } = useContext(MaterialesContext);
  return (
    <>
      {isLoading ? (
        <SpinnerAxn />
      ) : aulas && aulas.length ? (
        aulas.map((aula) => (
          <React.Fragment key={aula.idCursoBase}>
            <Heading size={"sm"}>{aula.nombreCursoBase}</Heading>
            <MainBox>
              {aula.cursosAcademicos && (
                <Accordion p={5}>
                  {aula.cursosAcademicos.map((cursoAcademico) => (
                    <AccordionItem key={cursoAcademico.id} p={5}>
                      {({ isExpanded }) => (
                        <MaterialesList
                          key={cursoAcademico.id}
                          cursoAcademico={cursoAcademico}
                          isExpanded={isExpanded}
                        />
                      )}
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </MainBox>
          </React.Fragment>
        ))
      ) : (
        <Alert status="info" variant={"left-accent"}>
          <AlertIcon />
          <p>No tienes materiales disponibles</p>
        </Alert>
      )}
    </>
  );
};
