import { createContext, useEffect, useReducer } from "react";
import axonApi from "../../Api/axonApi";
import { ContextProps } from "../../Interfaces/interfaces";
import {
  RequisitosInterface,
  Estado,
  Requisito,
  QuestionInterface,
} from "../../Interfaces/requisitos/requisitosInterface";
import { requisitosReducer } from "./requisitoReducer";

const REQUISITO_ENTREGADO = 5;

export interface RequisitosContextInterface {
  tabsOptions: {
    [k: number]: { requisitos: RequisitosInterface[]; tabTitle: string };
  };
  isLoading: boolean;
  questions: QuestionInterface[];
  estados: Estado[];
  updateRequisito: (idAcuerdo: string, requisito: Requisito) => void;
}

export const RequisitosContext = createContext(
  {} as RequisitosContextInterface
);

type Props = {
  idEvaluacion: number;
  requisitoSecundario: number;
};

export const RequisitosProvider = ({
  children,
  idEvaluacion,
  requisitoSecundario,
}: ContextProps & Props) => {
  const initialValues = {
    tabsOptions: {
      [REQUISITO_ENTREGADO]: { requisitos: [], tabTitle: "Por Revisar" },
      [requisitoSecundario]: {
        requisitos: [],
        tabTitle: "Historial de Revisados",
      },
    },
    isLoading: true,
    questions: [],
    estados: [],
    updateRequisito: () => {},
  };
  const [state, dispatch] = useReducer(requisitosReducer, initialValues);
  useEffect(() => {
    (async () => {
      await Promise.allSettled([
        fetchData(REQUISITO_ENTREGADO),
        fetchData(requisitoSecundario),
        getQuestions(),
        getEstados(),
      ]);
      dispatch({ type: "setLoading", payload: false });
    })();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (idEstado: number) => {
    const { data } = await axonApi.get<RequisitosInterface[]>(
      `/master/profesores/requisitos/${idEvaluacion}?idestado=${idEstado}`
    );

    const formattedData = {
      idEstado,
      requisitos: data,
    };

    dispatch({
      type: "setRequisitos",
      payload: formattedData,
    });
  };

  const getQuestions = async () => {
    const { data } = await axonApi.get<QuestionInterface[]>(
      `/usuarios/profesores/requisitos/acuerdos/preguntas`
    );
    dispatch({ type: "setQuestions", payload: data });
  };

  const getEstados = async () => {
    const { data } = await axonApi.get<Estado[]>(
      "/master/profesores/requisitos/estados"
    );
    const formattedData = data.filter((estado) =>
      ["10", "6", "11"].includes(estado.id)
    );
    dispatch({ type: "setEstados", payload: formattedData });
  };

  const updateRequisito = (idAcuerdo: string, requisito: Requisito) => {
    if (parseInt(requisito.estado.id) === requisitoSecundario) {
      // Agrego requisito a la lista de completados
      const acuerdosCorregidos = state.tabsOptions[
        requisitoSecundario
      ].requisitos.map((acuerdo) => {
        if (acuerdo.idCurso === requisito.idCurso) {
          return { ...acuerdo, requisitos: [...acuerdo.requisitos, requisito] };
        } else return acuerdo;
      });
      dispatch({
        type: "setRequisitos",
        payload: {
          requisitos: acuerdosCorregidos,
          idEstado: requisitoSecundario,
        },
      });
    }

    //Elimino requisito de la lista de entregados
    let acuerdosEntregados = state.tabsOptions[
      REQUISITO_ENTREGADO
    ].requisitos.map((acuerdo) => {
      return {
        ...acuerdo,
        requisitos: acuerdo.requisitos.filter(
          (requisito) => requisito.id !== idAcuerdo
        ),
      };
    });

    // Elimino fila de acuerdos si no tiene requisitos pendientes de correccion
    acuerdosEntregados = acuerdosEntregados.filter(
      (acuerdo) => acuerdo.requisitos.length !== 0
    );

    dispatch({
      type: "setRequisitos",
      payload: {
        requisitos: acuerdosEntregados,
        idEstado: REQUISITO_ENTREGADO,
      },
    });
  };

  return (
    <RequisitosContext.Provider value={{ ...state, updateRequisito }}>
      {children}
    </RequisitosContext.Provider>
  );
};
