import { Calendar } from "react-calendar";
import styled from "styled-components";

export const CustomCalendar = styled(Calendar)`
  width: 100%;
  border: none;
  padding: 10px;

  button {
    border-radius: 10px;
    padding: 10px;

    &.react-calendar__month-view__days__day--neighboringMonth {
      color: #a5a5a5;
    }

    &.react-calendar__tile--now:focus,
    &.react-calendar__tile--now:hover,
    &.react-calendar__tile--now:active {
      background: #e6e6e6;
    }
    
    &.react-calendar__tile--now {
      background: transparent;
    }

    &.react-calendar__tile--active {
      background: #006edc!important;
    }
  }
`;
