import React from "react";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { PerfilAcademicoInterface } from "../../../Interfaces/perfilAcademicoInterface";
import { LaboralItem } from "./Item";
import { TableBox } from "../../Layouts/Global.styles";
type Props = {
  data: PerfilAcademicoInterface;
  handleInformation: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "laboral",
    key: number
  ) => void;
  deleteItem: (type: "laboral", key: number) => void;
};

export const LaboralList = ({ data, handleInformation, deleteItem }: Props) => {
  return (
    data.laboral && (
      <TableBox>
        <Table>
          <Thead>
            <Tr>
              <Th>Empresa</Th>
              <Th>Puesto</Th>
              <Th>Año de finalización</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.laboral.map((laboral, key) => (
              <LaboralItem
                key={key}
                position={key}
                laboral={laboral}
                handleInformation={handleInformation}
                deleteItem={deleteItem}
              />
            ))}
          </Tbody>
        </Table>
      </TableBox>
    )
  );
};
