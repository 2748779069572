import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Select,
  Spinner,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { usePerfilAcademico } from "../../Hooks/PerfilAcademico/usePerfilAcademico";
import { EducacionList } from "./EducacionList";
import { LaboralList } from "./Laboral/List";

export const Perfil = () => {
  const {
    data,
    isLoading,
    disabled,
    loadingInterface,
    acreditaciones,
    handleChange,
    addItem,
    handleInformation,
    deleteItem,
    uploadProfile,
  } = usePerfilAcademico();
  return (
    <div>
      {loadingInterface ? (
        <Center>
          <Spinner
            color="blue.500"
            mt="4rem"
            size="xl"
            thickness="10px"
            emptyColor="red.100"
          />
        </Center>
      ) : (
        <>
          <Heading color={"gray.600"} m={5} ml={0}>
            Perfil académico
          </Heading>

          <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple>
            <AccordionItem>
              <Stack bgColor={"white"} p={5} borderRadius={5}>
                <AccordionButton>
                  <Box flex={"1"} textAlign="left">
                    <Heading color={"gray.600"} size={"md"}>
                      Perfil Profesional
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel>
                  <Textarea
                    name="acerca"
                    placeholder="Perfil Profesional"
                    value={data.acerca}
                    minH={40}
                    onChange={handleChange}
                  />
                </AccordionPanel>
              </Stack>
            </AccordionItem>
            <AccordionItem>
              <Stack bgColor={"white"} p={5} borderRadius={5} mt={5}>
                <AccordionButton>
                  <HStack flex={"1"}>
                    <Heading color={"gray.600"} size={"md"}>
                      Educación
                    </Heading>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel>
                  {data.educacion.length < 5 && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        colorScheme={"green"}
                        aria-label="Agregar educacion"
                        rightIcon={<FaPlus />}
                        size={"sm"}
                        onClick={() => addItem("educacion")}
                      >
                        Agregar
                      </Button>
                    </Box>
                  )}
                  <EducacionList
                    data={data}
                    handleInformation={handleInformation}
                    deleteItem={deleteItem}
                  />
                </AccordionPanel>
              </Stack>
            </AccordionItem>
            <AccordionItem>
              <Stack bgColor={"white"} p={5} borderRadius={5} mt={5}>
                <AccordionButton>
                  <Box flex={"1"} textAlign="left">
                    <Heading color={"gray.600"} size={"md"}>
                      Experiencia Laboral
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {data.laboral.length < 5 && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        colorScheme={"green"}
                        aria-label="Agregar laboral"
                        rightIcon={<FaPlus />}
                        size={"sm"}
                        onClick={() => addItem("laboral")}
                      >
                        Agregar
                      </Button>
                    </Box>
                  )}
                  <LaboralList
                    data={data}
                    handleInformation={handleInformation}
                    deleteItem={deleteItem}
                  />
                </AccordionPanel>
              </Stack>
            </AccordionItem>
            <AccordionItem>
              <Stack bgColor={"white"} p={5} borderRadius={5} mt={5}>
                <AccordionButton>
                  <Box flex={"1"} textAlign={"left"}>
                    <Heading color={"gray.600"} size={"md"}>
                      Objetivos/Habilidades/Competencias:
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Textarea
                    name="objetivos"
                    placeholder="Objetivos/Habilidades/Competencias:"
                    value={data.objetivos}
                    minH={40}
                    onChange={handleChange}
                  />
                </AccordionPanel>
              </Stack>
            </AccordionItem>
          </Accordion>

          <Stack bgColor={"white"} p={5} borderRadius={5} mt={5}>
            <Heading color={"gray.600"} size={"md"}>
              Acreditación
            </Heading>

            <Select
              name="idAcreditacion"
              value={data.idAcreditacion}
              onChange={handleChange}
            >
              {acreditaciones &&
                acreditaciones.map((acreditacion) => (
                  <option key={acreditacion.id} value={acreditacion.id}>
                    {acreditacion.nombre}
                  </option>
                ))}
            </Select>
          </Stack>
          <Stack align={"center"}>
            <Button
              disabled={(isLoading || disabled) && data.idAcreditacion === ""}
              isLoading={isLoading}
              colorScheme={"blue"}
              mt={5}
              mb={10}
              onClick={uploadProfile}
            >
              Guardar
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};
