import React, { useEffect, useContext } from "react";
import { SessionContext } from "../../Context/session/SessionContext";
import { useNavigate } from "react-router-dom";
import Login from "../Layouts/Login/Login";

export const LoginPage = () => {
  const { isLoading, session } = useContext(SessionContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (session && !isLoading) {
      navigate("/");
    }
  }, [session, isLoading, navigate]);

  return <Login docente={true}></Login>;
};
