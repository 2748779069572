import { useToast } from "@chakra-ui/react";
import { useEffect, useReducer, useContext, createContext } from "react";
import axonApi from "../../../Api/axonApi";
import {
  OrdenInterface,
  OrdenesVigentes,
  NewOrderInterface,
  CargaOrdenInterface,
} from "../../../Interfaces/ordenesInterface";
import { SessionContext } from "../../session/SessionContext";
import { ordenesReducer } from "./ordenesReducer";

export interface OrdenesInterface {
  tabSelected: number;
  ordenes: OrdenInterface[];
  vigentes: OrdenesVigentes[];
  newOrder: NewOrderInterface;
  isLoading: boolean;
  loadingGenerateOrderForm: boolean;
  tiposFacturas: { id: string; tipo: string }[];
  getOrdenes: (periodo?: string) => void;
  getNewOrder: (periodo: string) => void;
  generateOrder: (periodo: string) => void;
  setTabIndex: (tab: number) => void;
  addInvoice: (period: string, values: CargaOrdenInterface, file: Blob) => void;
}

export const OrdenesContext = createContext({} as OrdenesInterface);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const initialValues = {
  tabSelected: 0,
  ordenes: [],
  vigentes: [],
  newOrder: {} as NewOrderInterface,
  isLoading: true,
  loadingGenerateOrderForm: false,
  tiposFacturas: [],
  getOrdenes: () => {},
  getNewOrder: () => {},
  generateOrder: () => {},
  setTabIndex: () => {},
  addInvoice: () => {},
};

export const OrdenesProvider = ({ children }: Props) => {
  const { session } = useContext(SessionContext);
  const toast = useToast();
  const [state, dispatch] = useReducer(ordenesReducer, initialValues);
  useEffect(() => {
    (async () => {
      const responses = [getOrdenes(), getValidContract(), getTypeFacturas()];
      await Promise.all(responses);
    })();
    // eslint-disable-next-line
  }, []);

  const getOrdenes = async (periodo: string = "") => {
    dispatch({ type: "loadingOrders", payload: true });
    let url = `/facturacion/ordenes/profesores/informacion?&profesor=${session?.id}&sector=${session?.sector.id}`;
    if (periodo.length) url += `&periodo=${periodo}`;
    try {
      const { data } = await axonApi.get<OrdenInterface[]>(url);
      dispatch({ type: "setOrdenes", payload: data });
    } catch (error: any) {
      dispatch({ type: "setOrdenes", payload: [] });
      toast({
        title: "Error",
        description: error.response.data.error,
        status: "error",
        isClosable: true,
      });
    }
    dispatch({ type: "loadingOrders", payload: false });
  };

  const getValidContract = async () => {
    try {
      const { data } = await axonApi.get<OrdenesVigentes[]>(
        "/facturacion/docentes/contratos/vigente"
      );
      dispatch({ type: "setVigentes", payload: data });
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  const getTypeFacturas = async () => {
    const { data } = await axonApi.get<{ id: string; tipo: string }[]>(
      "/facturacion/v2/facturas"
    );
    dispatch({ type: "setTypesFacturas", payload: data });
  };

  const getNewOrder = async (period: string) => {
    dispatch({ type: "loadingGenerate", payload: true });
    try {
      const { data } = await axonApi.get<NewOrderInterface>(
        `/facturacion/ordenes/profesores?periodo=${period}&profesor=${session?.id}`
      );
      dispatch({ type: "setNewOrder", payload: data });
    } catch (error: any) {
      dispatch({ type: "setNewOrder", payload: {} as NewOrderInterface });
      toast({
        title: "Error",
        description: error.response.data.error,
        status: "error",
        isClosable: true,
      });
    }
    dispatch({ type: "loadingGenerate", payload: false });
  };

  const generateOrder = async (period: string) => {
    dispatch({ type: "loadingGenerate", payload: true });
    try {
      await axonApi.post(
        `/facturacion/ordenes/profesores/pdf?periodo=${period}&profesor=${session?.id}`,
        state.newOrder
      );
      dispatch({ type: "setNewOrder", payload: {} as NewOrderInterface });
      await getOrdenes();
      dispatch({ type: "loadingGenerate", payload: false });
    } catch (error: any) {
      dispatch({ type: "loadingGenerate", payload: false });
      throw error.response.data.error;
    }
  };

  const setTabIndex = (tab: number) => {
    dispatch({ type: "setTabIndex", payload: tab });
  };

  const addInvoice = async (
    period: string,
    values: CargaOrdenInterface,
    file: Blob
  ) => {
    const formFile = new FormData();
    formFile.append("archivo", file);
    formFile.append("data", JSON.stringify(values));

    try {
      await axonApi.post(
        `/facturacion/ordenes/profesores/facturas?periodo=${period}&profesor=${session?.id}&nombre=${session?.name}`,
        formFile
      );
      await getOrdenes();
    } catch (error: any) {
      throw error.response.data.error;
    }
  };

  return (
    <OrdenesContext.Provider
      value={{
        ...state,
        getOrdenes,
        getNewOrder,
        generateOrder,
        setTabIndex,
        addInvoice,
      }}
    >
      {children}
    </OrdenesContext.Provider>
  );
};
