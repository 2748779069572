import { ObservadosListInterface } from "../../../Interfaces/requisitos/observadosInterface";
import { ObservadoContextInterface } from "./ObservadosContext";

export type ActionType =
  | { type: "setObservados"; payload: ObservadosListInterface }
  | { type: "setLoading"; payload: boolean };

export const observadosReducer = (
  state: ObservadoContextInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setObservados":
      return { ...state, observados: action.payload };
    case "setLoading":
      return { ...state, isLoading: action.payload };
    default:
      return { ...state };
  }
};
