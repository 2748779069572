import styled from 'styled-components'
import { Accordion } from '@chakra-ui/react'

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  background-color: ${(props) => props.theme.sidebar.backgroundColor};

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  transform: translateX(-100%);

  width: 80%;
  height: 100%;
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  transition: ${(props) => props.theme.sidebar.transition};

  @media (min-width: 481px) {
    width: 20%;
    max-width: 500px;
    min-width: 300px;
  }
`

export const AccordionGroup = styled(Accordion)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
`
