import { useEffect, useContext } from "react";
import { Stack, Box } from "@chakra-ui/react";
import { BuscadorClasesContext } from "../../Context/buscadorClasesContext";
import { FormContainer, FormField } from "./Form";
import { OptionsInterface } from "../../Interfaces/interfaces";

interface Props {
  initialValues?: { [k: string]: any };
  servicesOptions?: OptionsInterface[];
  validation: object;
  mb?: number;
  group: {
    isDisabled: boolean;
    options: OptionsInterface[];
  };
}

export const BuscadorCursos = ({
  initialValues = {},
  servicesOptions,
  validation,
  group,
  mb,
}: Props) => {
  const { allServices, servicios, isLoading, getCursos } = useContext(
    BuscadorClasesContext
  );

  useEffect(() => {
    (async () => {
      await getCursos(initialValues);
    })();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values: any) => {
    await getCursos(values);
  };

  return (
    <Box backgroundColor={"white"} p={5} mt={5} mb={mb}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validation}
        handleSubmit={(values) => onSubmit(values)}
        render={(values: any) => {
          return (
            <>
              <Stack direction={["column", "row"]} mb={5}>
                <FormField
                  label="Grupo"
                  name={"grupo"}
                  inputType="react-select"
                  options={group.options}
                  placeholder={"Todos los grupos"}
                  isDisabled={group.isDisabled || isLoading}
                  cleanSelector={"servicio"}
                />

                <FormField
                  label="Servicio"
                  name={"servicio"}
                  inputType="react-select"
                  options={
                    servicesOptions ||
                    (values.grupo ? servicios[values.grupo] : allServices)
                  }
                  placeholder={"Todos los servicios"}
                  isDisabled={isLoading}
                />

                <FormField
                  label="Estado"
                  name={"estado"}
                  inputType="react-select"
                  options={[
                    { value: "1", label: "Por iniciar" },
                    { value: "2", label: "En curso" },
                    { value: "3", label: "Finalizada" },
                  ]}
                  placeholder={"Todos los estados"}
                  isDisabled={isLoading}
                />
              </Stack>
              <FormField
                inputType="submit"
                colorScheme={"orange"}
                justifyContent={"end"}
                placeholder={"Buscar"}
                isLoading={isLoading}
              />
            </>
          );
        }}
      />
    </Box>
  );
};
