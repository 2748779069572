import { MaterialesInterface } from "../../Interfaces/formaciones/materialesInterface";
import {
  AsistenciasAlumnosInterface,
  ClasesInterface,
} from "../../Interfaces/formacionesInterface";

export type ActionType =
  | { type: "setClases"; payload: ClasesInterface[] }
  | { type: "setAlumnos"; payload: AsistenciasAlumnosInterface[] }
  | { type: "setMateriales"; payload: MaterialesInterface[] };

type FormacionInterface = {
  clases: ClasesInterface[];
  alumnos: AsistenciasAlumnosInterface[];
  materiales: MaterialesInterface[];
};

export const formacionReducer = (
  state: FormacionInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setClases":
      return { ...state, clases: action.payload };
    case "setAlumnos":
      return { ...state, alumnos: action.payload };
    case "setMateriales":
      return { ...state, materiales: action.payload };
    default:
      return { ...state };
  }
};
