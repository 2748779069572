import { useState } from "react";
import axonApi from "../../../Api/axonApi";
import { AlumnosInterface } from "../../../Interfaces/requisitos/requisitosInterface";

export const useRevision = (idCursoAca: number) => {
  const [alumnos, setAlumnos] = useState<AlumnosInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await axonApi.get(
        `/master/profesores/cursos/${idCursoAca}`
      );
      setAlumnos(data.alumnos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { alumnos, loading, fetchData };
};
