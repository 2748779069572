import {
  Alert,
  AlertIcon,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { usePostulacionesStaff } from "../../Hooks/PostulacionStaff/usePostulacionesStaff";
import { MainBox } from "../Layouts/MainBox";
import { MainTitle } from "../Layouts/MainTitle";
import { SpinnerAxn } from "../Layouts/SpinnerAxn";
import { PostulacionItem } from "./PostulacionItem";

export const PostulacionesStaffPage = () => {
  const { isLoading, postulaciones } = usePostulacionesStaff();
  return (
    <div>
      <MainTitle title="Postulaciones Pendientes" />

      {isLoading ? (
        <SpinnerAxn />
      ) : postulaciones && postulaciones.length > 0 ? (
        <MainBox>
          <Table>
            <Thead>
              <Tr>
                <Th>Carrera</Th>
                <Th>Alumno</Th>
                <Th>Cuestionario</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {postulaciones.map((postulacion) => (
                <PostulacionItem
                  key={postulacion.id}
                  postulacion={postulacion}
                />
              ))}
            </Tbody>
          </Table>
        </MainBox>
      ) : (
        <Alert status={"info"} variant={"left-accent"}>
          <AlertIcon />
          No tenés postulaciones disponibles
        </Alert>
      )}
    </div>
  );
};
