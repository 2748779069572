import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { SessionContext } from "../../Context/session/SessionContext";
import { useFormacion } from "../../Hooks/Formaciones/useFormacion";
import { EstadosAcademicosInterface } from "../../Interfaces/formacionesInterface";
import { CursosInterface } from "../../Interfaces/sessionInterface";
import { SpinnerAxn } from "../Layouts/SpinnerAxn";
import { Alumnos } from "./Tabs/Alumnos";
import { Clases } from "./Tabs/Clases";
import { Materiales } from "./Tabs/Materiales";
import { useRevision } from "../../Hooks/Requisitos/Revision/useRevision";
import ResumenAlumnosEstados from "./Tabs/ResumenAlumnosEstados";
import { TableEntregas } from "../Requisitos/Resumen/TableEntregas";



type Props = {
  formacion: CursosInterface;
  estadosCursada: EstadosAcademicosInterface[];
};

export const Formacion = ({ formacion, estadosCursada }: Props) => {
  const { chargeData, isLoading, alumnos: alumnosGeneral, clases, materiales } =
    useFormacion(formacion);
  const { session } = useContext(SessionContext);
  const { loading, alumnos: alumnosRequisitos, fetchData } = useRevision(parseInt(formacion.id));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0); // New state to track active tab index

  const tabsPanels = [
    {
      name: "Resumen Alumnos",
      component: <ResumenAlumnosEstados idAula={formacion.id} />
    },
    {
      name: "Clases",
      component: <Clases clases={clases} />,
    },
    {
      name: "Alumnos",
      component: <Alumnos alumnos={alumnosGeneral} estadosCursada={estadosCursada} />,
    },
    {
      name: "Materiales",
      component: <Materiales materiales={materiales} />,
      link: materiales.length ? materiales[0].linkMaterial : null,
      disable: session?.sector.id === "12" && formacion.grupo === "1",
    },
    {
      name: "Requisitos",
      component: loading ? <SpinnerAxn /> : <TableEntregas alumnos={alumnosRequisitos} />,
      fetchDataRequired: true
    },
  ];

  return (
    <AccordionItem
      onClick={() => setIsOpen(!isOpen)}>
      <Stack backgroundColor={"white"} p={5} mb={5}>
        <AccordionButton onClick={() => chargeData()}>
          <Text flex={"1"} textAlign={"left"}>
            {formacion.alias}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Tabs variant="soft-rounded" align="center" colorScheme="blue" onChange={(index) => setActiveTabIndex(index)}>
            <TabList>
              {tabsPanels.map((tab, index) => (
                <Tab
                  isDisabled={isLoading || tab.disable}
                  key={index}
                  onClick={() => {
                    if (tab.fetchDataRequired && index === activeTabIndex) {
                      fetchData(); 
                    }
                    if (tab.link) {
                      window.open(tab.link, "_blank");
                    }
                  }}
                >
                  <Tooltip label={tab.disable ? "No disponible para staffes" : ""}>
                    {tab.name}
                  </Tooltip>
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabsPanels.map((tab, index) => (
                <Box key={index} width={"calc(100vw - 10rem)"}>
                  <TabPanel key={index} overflowX={"auto"}>
                    {isLoading ? <SpinnerAxn /> : tab.component}
                  </TabPanel>
                </Box>
              ))}
            </TabPanels>
          </Tabs>
        </AccordionPanel>
      </Stack>
    </AccordionItem>
  );
};