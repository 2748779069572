import { RequisitosProvider } from "../../../Context/requisitos/RequisitosContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { AcuerdosList } from "./AcuerdosList";

export const AcuerdosPage = () => {
  return (
    <RequisitosProvider idEvaluacion={6} requisitoSecundario={10}>
      <MainTitle title="Acuerdos de Coaching" />

      <AcuerdosList />
    </RequisitosProvider>
  );
};
