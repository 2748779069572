import { RequisitosProvider } from "../../../Context/requisitos/RequisitosContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { TabsContent } from "./TabsContent";

export const PracticasPage = () => {
  return (
    <RequisitosProvider idEvaluacion={7} requisitoSecundario={2}>
      <MainTitle title="Práctica de coaching" />
      <TabsContent />
    </RequisitosProvider>
  );
};
