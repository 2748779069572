import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #2c5282;

  width: 100%;
  height: 100%;
  z-index: 5;
  padding: 0 2rem;
`
