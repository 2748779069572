import { Heading } from "@chakra-ui/react";

type Props = {
  title: string;
};

export const MainTitle = ({ title }: Props) => {
  return (
    <Heading color={"gray.600"} m={5} ml={0}>
      {title}
    </Heading>
  );
};
