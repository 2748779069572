import { OrdenesProvider } from "../../../Context/docentes/ordenesDePago/ordenesContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { Ordenes } from "./Ordenes";

export const OrdenDePagoPage = () => {
  return (
    <OrdenesProvider>
      <MainTitle title="Orden de pago" />

      <Ordenes />
    </OrdenesProvider>
  );
};
