import {
  AulasInterface,
  Materiales,
  MaterialesInterface,
} from "../../Interfaces/materiales/materialesInterface";
import { MaterialesContextInterface } from "./materialesContext";

type ActionType =
  | { type: "setAulas"; payload: AulasInterface[] }
  | {
      type: "setMateriales";
      payload: { materiales: MaterialesInterface[]; idCursoAca: string };
    }
  | {
      type: "updateMateriales";
      payload: { materiales: Materiales; idCursoAca: string; idClase: string };
    }
  | {
      type: "deleteMateriales";
      payload: { idMaterial: string; idCursoAca: string; idClase: string };
    };

export const materialesReducer = (
  state: MaterialesContextInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setAulas":
      return { ...state, aulas: action.payload, isLoading: false };
    case "setMateriales":
      return {
        ...state,
        aulas: state.aulas.map((aula) => {
          return {
            ...aula,
            cursosAcademicos: aula.cursosAcademicos.map((cursoAcademico) => {
              if (cursoAcademico.id === action.payload.idCursoAca) {
                return {
                  ...cursoAcademico,
                  materiales: action.payload.materiales,
                };
              } else return cursoAcademico;
            }),
          };
        }),
      };
    case "updateMateriales":
      return {
        ...state,
        aulas: state.aulas.map((aula) => {
          return {
            ...aula,
            cursosAcademicos: aula.cursosAcademicos.map((cursoAca) =>
              cursoAca.id === action.payload.idCursoAca
                ? {
                    ...cursoAca,
                    materiales:
                      cursoAca.materiales &&
                      cursoAca.materiales.map((mat) =>
                        mat.idClase === action.payload.idClase
                          ? {
                              ...mat,
                              materiales: mat.materiales.some(
                                (material) =>
                                  material.id === action.payload.materiales.id
                              )
                                ? mat.materiales.map((material) => {
                                    if (
                                      material.id ===
                                      action.payload.materiales.id
                                    ) {
                                      return action.payload.materiales;
                                    }
                                    return material;
                                  })
                                : [
                                    ...mat.materiales,
                                    action.payload.materiales,
                                  ],
                            }
                          : mat
                      ),
                  }
                : cursoAca
            ),
          };
        }),
      };

    case "deleteMateriales":
      return {
        ...state,
        aulas: state.aulas.map((aulas) => {
          return {
            ...aulas,
            cursosAcademicos: aulas.cursosAcademicos.map((cursosAca) =>
              cursosAca.id === action.payload.idCursoAca && cursosAca.materiales
                ? {
                    ...cursosAca,
                    materiales: cursosAca.materiales.map((mat) =>
                      mat.idClase === action.payload.idClase
                        ? {
                            ...mat,
                            materiales: mat.materiales.filter(
                              (material) =>
                                material.id !== action.payload.idMaterial
                            ),
                          }
                        : mat
                    ),
                  }
                : cursosAca
            ),
          };
        }),
      };

    default:
      return { ...state };
  }
};
