import { Badge, Td, Tooltip, Tr } from "@chakra-ui/react";
import { SpinnerAxn } from "../../Components/Layouts/SpinnerAxn";
import {
  AsistenciasAlumnosInterface,
  EstadosAcademicosInterface,
} from "../../Interfaces/formacionesInterface";

type BadgeProps = {
  [k: string]: string;
};

const asistenciaBadge: BadgeProps = {
  1: "green",
  2: "red",
  3: "blue",
  4: "yellow",
};
export const useAsistencias = (
  alumnosClases: AsistenciasAlumnosInterface[]
) => {
  const filtrarAsistencias = (estado: EstadosAcademicosInterface) => {
    if (alumnosClases.length) {
      const alumnosEstados = alumnosClases[0].asistencias.filter(
        (asistencia) => asistencia.estado_aca.id === estado.id
      );

      return alumnosEstados.map((alumno, key) => (
        <Tr key={key}>
          <Td> 
            {alumno.alumno.nombre}
            {
              alumno.alumno.deudor == "1" ?
              (
                <span
                  style={{fontWeight: "bold"}}
                > (Deudor)</span>
              ) : ""
            }
          </Td>
          {alumnosClases.map((alumnoAsistencia, nClase) =>
            asistencias(alumno.alumno.id, nClase)
          )}
        </Tr>
      ));
    } else {
      return <SpinnerAxn />;
    }
  };
  const asistencias = (idAlumno: string | null, nClase: number) => {
    const alumno = alumnosClases[nClase].asistencias.filter(
      (asistencia) => asistencia.alumno.id === idAlumno
    )[0];
    return (
      <Td key={`${idAlumno}${nClase}`}>
        {alumno && alumno.estado ? (
          <Badge
            colorScheme={asistenciaBadge[alumno.estado.id]}
            fontSize={"1rem"}
          >
            <Tooltip
              hasArrow
              label={alumno.estado.id === "3" ? alumno.motivo.nombre : ""}
            >
              {alumno.estado.abreviado}
            </Tooltip>
          </Badge>
        ) : (
          "-"
        )}
      </Td>
    );
  };
  return { filtrarAsistencias };
};
