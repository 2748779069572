import { useEffect, useState } from "react";
import axonApi from "../../Api/axonApi";
import {
  AlertaInterface,
  RequisitosAlertas,
} from "../../Interfaces/alertasInterface";

type infoRequisitoInterface = {
  [k: string]: dataRequisitoInterface;
};

type dataRequisitoInterface = {
  color: "info" | "warning";
  redireccion: string;
};

export const useAlertas = () => {
  const [alertas, setAlertas] = useState<RequisitosAlertas>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axonApi.get<RequisitosAlertas>(
          "/master/profesores/alertas"
        );
        setAlertas(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const formData = async (value: AlertaInterface) => {
    const { nombre, idCurso } = value;
    if (nombre === "formacion") {
      try {
        await axonApi.put("/master/profesores/alertas", {
          curso: idCurso,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const infoRequisitos: infoRequisitoInterface = {
    observados: {
      color: "info",
      redireccion: "/requisitos/observados",
    },
    acuerdos: {
      color: "info",
      redireccion: "/requisitos/acuerdos",
    },
    practicas: {
      color: "warning",
      redireccion: "/requisitos/practicas",
    },
    coaching: {
      color: "info",
      redireccion: "/staff/coaching/correccion",
    },
    postulaciones: {
      color: "info",
      redireccion: "/postulaciones-staff",
    },
    aulas: {
      color: "info",
      redireccion: "/docentes/postulaciones",
    },
    formacion: {
      color: "info",
      redireccion: "/requisitos/observados",
    },
  };

  return { isLoading, alertas, infoRequisitos, formData };
};
