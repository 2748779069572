import { useContext } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Flex,
  Badge,
  AccordionPanel,
  Text,
  Box,
} from "@chakra-ui/react";
import { ObservadoContext } from "../../../../Context/requisitos/observados/ObservadoContext";
import { Marcadores } from "./Marcadores";
import { MainBox } from "../../../Layouts/MainBox";

export const Entrega = () => {
  const {
    correccion: { competencias, observacionFinal },
  } = useContext(ObservadoContext);

  const badgeColor: { [k: string]: string } = {
    Inexperto: "red",
    Principiante: "yellow",
    Competente: "blue",
    Virtuoso: "green",
  };

  return (
    <>
      <Accordion backgroundColor={"white"} allowMultiple>
        {competencias.map((competencia, key) => (
          <AccordionItem p={5} key={key}>
            <AccordionButton>
              <Flex
                flex={1}
                gap={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text>
                  C{key + 1}: {competencia.nombre}
                </Text>
                {competencia.nivel && (
                  <Badge colorScheme={badgeColor[competencia.nivel]}>
                    {competencia.nivel}
                  </Badge>
                )}
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Box textAlign={"center"}>
                <Marcadores marcadores={competencia.marcadores} />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <MainBox>
        <Text fontSize={"xl"} as={"b"}>
          Observación Final:
        </Text>

        <Text>{observacionFinal}</Text>
      </MainBox>
    </>
  );
};
