import styled from 'styled-components'

export const ProfileAndLogout = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
`
export const UserAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;

  @media (min-width: 481px) {
    width: 2.5rem;
  }
`
