import { createContext, useEffect, useReducer } from "react";
import axonApi from "../../Api/axonApi";
import { ContextProps } from "../../Interfaces/interfaces";
import {
  AulasInterface,
  Materiales,
  MaterialesInterface,
} from "../../Interfaces/materiales/materialesInterface";
import { materialesReducer } from "./materialesReducer";

export interface MaterialesContextInterface {
  aulas: AulasInterface[];
  isLoading: boolean;
  getMateriales: (idCursoAca: string) => void;
  updateMateriales: (
    idCursoAca: string,
    idClase: string,
    material: Materiales
  ) => void;
  replaceMateriales: (
    material: MaterialesInterface[],
    idCursoAca: string
  ) => void;
  deleteMaterial: (
    idCursoAca: string,
    idClase: string,
    idMaterial: string
  ) => void;
}

export const MaterialesContext = createContext(
  {} as MaterialesContextInterface
);

const initialState = {
  aulas: [],
  isLoading: true,
  getMateriales: () => {},
  updateMateriales: () => {},
  replaceMateriales: () => {},
  deleteMaterial: () => {},
};

export const MaterialesProvider = ({ children }: ContextProps) => {
  const [state, dispatch] = useReducer(materialesReducer, initialState);

  useEffect(() => {
    const getAulas = async () => {
      try {
        const { data } = await axonApi.get<AulasInterface[]>(
          "/usuarios/profesores/cursos/aulas"
        );

        // Si es array agrego un nuevo parametro a la interface cursoAcademico para obtener las aulas restantes
        const payload = Array.isArray(data)
          ? data.map((aula) => {
              return {
                ...aula,
                cursosAcademicos: aula.cursosAcademicos.map((cursoAca) => {
                  return {
                    ...cursoAca,
                    restAulas: aula.cursosAcademicos
                      .filter((aulaActual) => aulaActual.id !== cursoAca.id)
                      .map((curso) => {
                        return { value: curso.id, label: curso.alias };
                      }),
                  };
                }),
              };
            })
          : [];
        dispatch({ type: "setAulas", payload });
      } catch (error) {
        dispatch({ type: "setAulas", payload: [] });
      }
    };

    getAulas();
  }, []);

  const getMateriales = async (idCursoAca: string) => {
    try {
      const { data } = await axonApi.get<MaterialesInterface[]>(
        `/usuarios/profesores/cursos/aulas/${idCursoAca}/materiales`
      );
      dispatch({
        type: "setMateriales",
        payload: { materiales: data, idCursoAca },
      });
    } catch (error) {
      dispatch({
        type: "setMateriales",
        payload: { materiales: [], idCursoAca },
      });
    }
  };

  const updateMateriales = (
    idCursoAca: string,
    idClase: string,
    materiales: Materiales
  ) => {
    dispatch({
      type: "updateMateriales",
      payload: { materiales, idClase, idCursoAca },
    });
  };

  const replaceMateriales = (
    materiales: MaterialesInterface[],
    idCursoAca: string
  ) => {
    dispatch({
      type: "setMateriales",
      payload: { materiales, idCursoAca },
    });
  };

  const deleteMaterial = (
    idCursoAca: string,
    idClase: string,
    idMaterial: string
  ) => {
    dispatch({
      type: "deleteMateriales",
      payload: { idMaterial, idCursoAca, idClase },
    });
  };

  return (
    <MaterialesContext.Provider
      value={{
        ...state,
        getMateriales,
        updateMateriales,
        deleteMaterial,
        replaceMateriales,
      }}
    >
      {children}
    </MaterialesContext.Provider>
  );
};
