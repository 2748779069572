import { useContext } from "react";
import { Tabs, TabList, Tab, TabPanels } from "@chakra-ui/react";
import { ObservadosContext } from "../../../Context/requisitos/observados/ObservadosContext";
import { MainBox } from "../../Layouts/MainBox";
import { SpinnerAxn } from "../../Layouts/SpinnerAxn";
import { TableObservados } from "./TableObservados";

export const Observados = () => {
  const { isLoading, observados } = useContext(ObservadosContext);

  const tabs = [
    { title: "Pendiente de Corrección", bgColor: "blue.200" },
    { title: "Aceptar", bgColor: "yellow.200" },
    { title: "Aprobado", bgColor: "green.200" },
    { title: "Volver a Presentar", bgColor: "red.200" },
  ];
  return isLoading ? (
    <SpinnerAxn />
  ) : (
    <Tabs variant={"soft-rounded"} colorScheme={"blue"}>
      <MainBox mb={0}>
        <TabList>
          {tabs.map((tab, key) => (
            <Tab key={key}>{tab.title}</Tab>
          ))}
        </TabList>
      </MainBox>
      <MainBox mt={0}>
        <TabPanels>
          {tabs.map((tab) => (
            <TableObservados
              key={tab.title}
              observados={observados[tab.title]}
              bgColor={tab.bgColor}
              aceptaProfesor={tab.title === "Aceptar"}
            />
          ))}
        </TabPanels>
      </MainBox>
    </Tabs>
  );
};
