import moment from "moment-timezone";

export const getDate = (date: Date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let day = dt.toString();
  let mes = month.toString();

  if (dt < 10) {
    day = "0" + dt;
  }
  if (month < 10) {
    mes = "0" + month;
  }
  return year + "-" + mes + "-" + day;
};

export const filtrarTimeZone = (
  timeFormat: Intl.ResolvedDateTimeFormatOptions,
  date: string = "0000-00-00",
  time: string = "00:00"
) => {
  const fecha = date.split("-");
  const hora = time.split(":");
  var timeArg = moment.tz(
    fecha[2] + "-" + fecha[1] + "-" + fecha[0] + " " + hora[0] + ":" + hora[1],
    "America/Argentina/Buenos_Aires"
  );
  var timeClient = timeArg.clone().tz(timeFormat.timeZone);
  date = timeClient.format("DD-MM-YYYY");
  time = timeClient.format("HH:mm");
  return { time, date };
};
