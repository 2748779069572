import React from "react";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { PerfilAcademicoInterface } from "../../Interfaces/perfilAcademicoInterface";
import { EducacionItem } from "./Educacion";
import { TableBox } from "../Layouts/Global.styles";
type Props = {
  data: PerfilAcademicoInterface;
  handleInformation: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "educacion",
    key: number
  ) => void;
  deleteItem: (type: "educacion", key: number) => void;
};

export const EducacionList = ({
  data,
  handleInformation,
  deleteItem,
}: Props) => {
  return (
    data.educacion && (
      <TableBox>
        <Table>
          <Thead>
            <Tr>
              <Th>Institución</Th>
              <Th>Carrera</Th>
              <Th>Año de finalización</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.educacion.map((educacion, key) => (
              <EducacionItem
                key={key}
                position={key}
                educacion={educacion}
                handleInformation={handleInformation}
                deleteItem={deleteItem}
              />
            ))}
          </Tbody>
        </Table>
      </TableBox>
    )
  );
};
