import React, { useState, useEffect, createContext, Dispatch, SetStateAction } from 'react'
import { PermisosData, Session, SidebarData } from './Interfaces'

interface WrapperContext {
  session: Session | null
  permisos: PermisosData
  accordionItems: SidebarData[]
  isInMobile: boolean
  currentPathname: string
  isSidebarOpen: boolean
  logout: () => void
  redirectTo: (pathname: string) => void
  setCurrentPathname: Dispatch<SetStateAction<string>>
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>
  hasPermiso: (nroPermiso: number) => boolean
  toggleSidebar: () => void
  animateBurguerMenu: () => void
}

export const WrapperSidebarContext = createContext<WrapperContext>({} as WrapperContext)

interface WrapperProvider {
  session: Session | null
  permisos: PermisosData
  data: SidebarData[]
  logout: () => void
  redirectTo: (pathname: string) => void
  children: JSX.Element
}

export const WrapperSidebarContextProvider = ({
  session,
  permisos,
  data,
  logout,
  redirectTo,
  children,
}: WrapperProvider) => {
  const [isInMobile, setIsInMobile] = useState<boolean>(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)
  const [currentPathname, setCurrentPathname] = useState<string>('')

  const hasPermiso = (nroPermiso: number) => {
    return !!permisos[nroPermiso]
  }

  const animateSidebarSwipe = () => {
    const $sidebar = document.querySelector('#sidebar') as HTMLDivElement
    $sidebar.style.transform = isSidebarOpen ? 'translateX(-100%)' : 'translateX(0)'
  }

  const animateBurguerMenu = () => {
    const firstLine = document.querySelector('#burguer-menu__line-1') as HTMLDivElement
    const secondLine = document.querySelector('#burguer-menu__line-2') as HTMLDivElement
    const thirdLine = document.querySelector('#burguer-menu__line-3') as HTMLDivElement

    firstLine.style.transform = isSidebarOpen ? 'rotate(0deg) translateY(0)' : 'rotate(45deg) translateY(.8rem)'
    secondLine.style.display = isSidebarOpen ? 'inline' : 'none'
    thirdLine.style.transform = isSidebarOpen ? 'rotate(0deg) translateY(0)' : 'rotate(-45deg) translateY(-.8rem)'
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)

    animateSidebarSwipe()
    animateBurguerMenu()
  }

  useEffect(() => {
    setIsInMobile(window.innerWidth <= 480)
  }, [])

  return (
    <WrapperSidebarContext.Provider
      value={{
        session,
        permisos,
        accordionItems: data,
        isInMobile,
        currentPathname,
        isSidebarOpen,
        logout,
        redirectTo,
        setCurrentPathname,
        setIsSidebarOpen,
        hasPermiso,
        toggleSidebar,
        animateBurguerMenu,
      }}
    >
      {children}
    </WrapperSidebarContext.Provider>
  )
}
