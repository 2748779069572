import { useContext } from "react";
import { Alert, AlertIcon, Box, Center } from "@chakra-ui/react";
import "react-calendar/dist/Calendar.css";
import * as S from "./Clases.styled";
import { ClasesContext } from "../../Context/clases/ClasesContext";
import { Clase } from "./Clase";
import { MainTitle } from "../Layouts/MainTitle";
import { SpinnerAxn } from "../Layouts/SpinnerAxn";

export const Clases = () => {
  const { clases, calendarDate, isLoading, onChangeDate } =
    useContext(ClasesContext);
  return (
    <div>
      <MainTitle title="Clases" />

      <S.CustomCalendar value={calendarDate} onChange={onChangeDate} />

      {isLoading ? (
        <Center mt={5}>
          <SpinnerAxn />
        </Center>
      ) : clases.length ? (
        <Box pb={5}>
          {clases.map((clase) => (
            <Clase key={clase.id} clase={clase} />
          ))}
        </Box>
      ) : (
        <Alert status="info" variant={"left-accent"} mt={5}>
          <AlertIcon />
          No se encontraron clases
        </Alert>
      )}
    </div>
  );
};
