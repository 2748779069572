import { useContext } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { MainBox } from "../../Layouts/MainBox";
import { SpinnerAxn } from "../../Layouts/SpinnerAxn";
import { AcuerdoItem } from "./AcuerdoItem";
import { RequisitosContext } from "../../../Context/requisitos/RequisitosContext";

export const AcuerdosList = () => {
  const { isLoading, tabsOptions } = useContext(RequisitosContext);

  return (
    <MainBox>
      <Tabs variant={"soft-rounded"} colorScheme={"blue"}>
        <TabList>
          {Object.values(tabsOptions).map((tab, key) => (
            <Tab key={key} isDisabled={isLoading}>
              {tab.tabTitle}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {Object.values(tabsOptions).map((tabItem, key) => (
            <TabPanel key={key}>
              {isLoading ? (
                <SpinnerAxn />
              ) : (
                <AcuerdoItem acuerdos={tabItem.requisitos} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </MainBox>
  );
};
