import React from "react";
import { Materiales } from "../../Interfaces/materiales/materialesInterface";
import { FormContainer, FormField } from "../Layouts/Form";
import * as Yup from "yup";
import { VStack } from "@chakra-ui/react";
import { useModalMaterial } from "../../Hooks/Materiales/useModalMaterial";
export interface ModalMaterialesProps {
  material?: Materiales;
  idCursoAca: string;
  idClase: string;
  onClose?: () => void;
}

export const ModalMaterial = ({
  material,
  idClase,
  idCursoAca,
  onClose,
}: ModalMaterialesProps) => {
  const { isLoading, handleChange, submitForm } = useModalMaterial({
    material,
    idClase,
    idCursoAca,
  });

  const validation = Yup.object().shape({
    descripcion: Yup.string().required("Campo requerido"),
  });

  const handleSubmit = async (values: any) => {
    try {
      await submitForm(values);
      if (onClose) onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <FormContainer
        initialValues={{
          descripcion: material?.descripcion || "",
        }}
        validationSchema={validation}
        handleSubmit={(values) => handleSubmit(values)}
      >
        <VStack gap={5}>
          <FormField
            name="file"
            label="El archivo debe pesar menos de 10mb:"
            inputType="file"
            handleChange={({
              target: { files },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(files);
            }}
          />
          <FormField
            label="Agregar Descripcion:"
            name={"descripcion"}
            inputType="input"
            isRequired
          />
          <FormField
            inputType="submit"
            placeholder="Guardar Material"
            colorScheme={"blue"}
            justifyContent={"end"}
            isLoading={isLoading}
          />
        </VStack>
      </FormContainer>
    </>
  );
};
