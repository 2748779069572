import { useState, useEffect } from "react";
import axonApi from "../../Api/axonApi";
import {
  PostulacionesStaffInterface,
  PostulacionStaffInfo,
} from "../../Interfaces/postulacionesStaffesInterface";

export const usePostulacionesStaff = () => {
  const [postulaciones, setPostulaciones] = useState<PostulacionStaffInfo[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    (async () => {
      const { data } = await axonApi.get<PostulacionesStaffInterface>(
        "/usuarios/staffes/pendientes"
      );
      setPostulaciones(data.datos);
      setIsLoading(false);
    })();
  }, []);
  return { isLoading, postulaciones };
};
