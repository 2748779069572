import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react";
import { RequisitosInterface } from "../../../Interfaces/requisitos/requisitosInterface";
import { Table } from "./Table";

interface Props {
  practicas: RequisitosInterface[];
}

export const Practicas = ({ practicas }: Props) => {
  return practicas && practicas.length ? (
    <Accordion allowMultiple>
      {practicas.map((practica) => (
        <AccordionItem key={practica.idCurso} p={5}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="center">
                {practica.alias}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table requisitos={practica.requisitos} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  ) : (
    <Alert status="info" variant={"left-accent"} mt={5}>
      <AlertIcon />
      No tienes practicas pendientes
    </Alert>
  );
};
