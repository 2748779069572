import {
  RequisitosInterface,
  Estado,
  QuestionInterface
} from "../../Interfaces/requisitos/requisitosInterface";
import { RequisitosContextInterface } from "./RequisitosContext";

export type ActionType =
  | {
      type: "setRequisitos";
      payload: { requisitos: RequisitosInterface[]; idEstado: number };
    }
  | {
      type: "setQuestions";
      payload: QuestionInterface[];
    }
  | {
      type: "setEstados";
      payload: Estado[];
    }
  | { type: "setLoading"; payload: boolean };

export const requisitosReducer = (
  state: RequisitosContextInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setRequisitos":
      return {
        ...state,
        tabsOptions: {
          ...state.tabsOptions,
          [action.payload.idEstado]: {
            ...state.tabsOptions[action.payload.idEstado],
            requisitos: action.payload.requisitos,
          },
        },
      };

    case "setQuestions":
      return { ...state, questions: action.payload };

    case "setEstados":
      return { ...state, estados: action.payload };

    case "setLoading":
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};
