import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { usePostulacion } from "../../../Hooks/Docentes/usePostulaciones";
import { Postulacion } from "../../../Interfaces/postulacionesInterface";

type Props = {
  postulacion: Postulacion;
};

export const PostulacionItem = ({ postulacion }: Props) => {
  const { addPostulacion, deletePostulacion, loading } =
    usePostulacion(postulacion);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePostulacion = async () => {
    try {
      if (postulacion.postulado) await deletePostulacion();
      else await addPostulacion();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tr>
        <Td>{postulacion.alias}</Td>
        <Td>{postulacion.horario}</Td>
        <Td>{postulacion.dia}</Td>
        <Td>{postulacion.inicio}</Td>
        <Td style={{textAlign: "center"}}>{postulacion.formador ?? "-"}</Td>
        <Td isNumeric>
          <Button
            colorScheme={postulacion.postulado ? "red" : "blue"}
            onClick={onOpen}
          >
            {postulacion.postulado ? "Borrar" : "Confirmar"}
          </Button>
        </Td>
      </Tr>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {postulacion.postulado ? "Borrar Postulacion" : "Postularse"} en{" "}
            {postulacion.alias}
          </ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button
              colorScheme={postulacion.postulado ? "red" : "blue"}
              onClick={handlePostulacion}
              isLoading={loading}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
