import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Tfoot,
} from "@chakra-ui/react";
import { AlumnosInterface } from "../../../Interfaces/requisitos/requisitosInterface";
import { MainBox } from "../../Layouts/MainBox";

interface Props {
  alumnos: AlumnosInterface[];
}

const ID_ACUERDO = "6";
const ID_OBSERVADO = "3";
const ID_ACUERDO_TALLER = "100";
const ID_CONVERSACION = "101";

export const TableEntregas = ({ alumnos }: Props) => {
  const getEstado = (alumnos: AlumnosInterface, requisito: string) => {
    return alumnos.evaluaciones.filter(
      (evaluacion) => evaluacion.id === requisito
    )[0].estado;
  };

  const estados: { [k: string]: string } = {
    NR: "No Realizado",
    PC: "Práctica de Coaching en Clase",
    TM: "Taller de Mentoring",
    VP: "Volver a presentar.",
  };

  return (
    <MainBox>
      <Table>
        <Thead>
          <Tr>
            <Th>Alumno</Th>
            <Th textAlign={"center"}>Acuerdo</Th>
            <Th textAlign={"center"}>Observado</Th>
            <Th textAlign={"center"}>Taller de Acuerdo</Th>
            <Th textAlign={"center"}>Taller Conversacion Completa</Th>

          </Tr>
        </Thead>
        <Tbody>
          {alumnos &&
            alumnos.map((alumnos) => (
              <Tr key={alumnos.id}>
                <Td>
                  {alumnos.nombre} {alumnos.apellido}
                </Td>
                <Td textAlign={"center"}>
                  <Tooltip label={estados[getEstado(alumnos, ID_ACUERDO)]}>
                    {getEstado(alumnos, ID_ACUERDO)}
                  </Tooltip>
                </Td>
                <Td textAlign={"center"}>
                  <Tooltip label={estados[getEstado(alumnos, ID_OBSERVADO)]}>
                    {getEstado(alumnos, ID_OBSERVADO)}
                  </Tooltip>
                </Td>
                <Td textAlign={"center"}>
                  <Tooltip label={estados[getEstado(alumnos, ID_ACUERDO_TALLER)]}>
                    {getEstado(alumnos, ID_ACUERDO_TALLER)}
                  </Tooltip>
                </Td>
                <Td textAlign={"center"}>
                  <Tooltip label={estados[getEstado(alumnos, ID_CONVERSACION)]}>
                    {getEstado(alumnos, ID_CONVERSACION)}
                  </Tooltip>
                </Td>
              </Tr>
            ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Alumno</Th>
            <Th textAlign={"center"}>Acuerdo</Th>
            <Th textAlign={"center"}>Observado</Th>
            <Th textAlign={"center"}>Taller de Acuerdo</Th>
            <Th textAlign={"center"}>Taller Conversacion Completa</Th>

          </Tr>
        </Tfoot>
      </Table>
    </MainBox>
  );
};
