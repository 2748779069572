import { AddIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MaterialesContext } from "../../Context/materiales/materialesContext";
import { useContext } from "react";
import { CursosAcademico } from "../../Interfaces/materiales/materialesInterface";
import ModalAxon from "../Layouts/Modal/ModalAxon";
import { SpinnerAxn } from "../Layouts/SpinnerAxn";
import { MaterialItem } from "./MaterialItem";
import { ModalMaterial } from "./ModalMaterial";
import { FormCopyMateriales } from "./FormCopyMateriales";

interface Props {
  cursoAcademico: CursosAcademico;
  isExpanded: boolean;
}

export const MaterialesList = ({ cursoAcademico, isExpanded }: Props) => {
  const { getMateriales } = useContext(MaterialesContext);

  return (
    <>
      <AccordionButton
        onClick={() =>
          !cursoAcademico.materiales && getMateriales(cursoAcademico.id)
        }
      >
        <p>{cursoAcademico.alias}</p>
      </AccordionButton>

      <AccordionPanel>
        {!cursoAcademico.materiales ? (
          <SpinnerAxn />
        ) : (
          <>
            <FormCopyMateriales cursoActual={cursoAcademico} />
            {cursoAcademico.materiales.length && isExpanded && (
              <Accordion allowToggle>
                {cursoAcademico.materiales.map(
                  ({ idClase, materiales }, key) => (
                    <AccordionItem key={idClase}>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Clase {key + 1}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel overflowX={"auto"}>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Archivo</Th>
                              <Th>Descripcion</Th>
                              <Th>
                                <ModalAxon
                                  trigger={
                                    <AddIcon
                                      color={"green.500"}
                                      cursor={"pointer"}
                                      fontSize={"20px"}
                                    />
                                  }
                                  render={({ onClose }) => (
                                    <ModalMaterial
                                      idCursoAca={cursoAcademico.id}
                                      idClase={idClase}
                                      onClose={onClose}
                                    />
                                  )}
                                  title={`Agregar material al curso academico ${cursoAcademico.id}`}
                                />
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {materiales.map((material) => (
                              <MaterialItem
                                key={material.id}
                                material={material}
                                idCurso={cursoAcademico.id}
                                idClase={idClase}
                              />
                            ))}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            )}
          </>
        )}
      </AccordionPanel>
    </>
  );
};
