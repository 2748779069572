import { useState, useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormBuscador } from "../FormBuscador";
import { OrdenesContext } from "../../../../Context/docentes/ordenesDePago/ordenesContext";
import { ClasesList } from "./ClasesList";
import { CorreccionesList } from "./CorreccionesList";
import { ServiciosList } from "./ServiciosList";

export const Generar = () => {
  const {
    newOrder,
    loadingGenerateOrderForm,
    getNewOrder,
    generateOrder,
    setTabIndex,
  } = useContext(OrdenesContext);
  const [periodo, setPeriodo] = useState<string>("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmit = async (values: { name: string; date: string }) => {
    setPeriodo(values.date);
    await getNewOrder(values.date);
  };

  const addOrder = async () => {
    try {
      await generateOrder(periodo);
      onClose();
      setTabIndex(0);
    } catch (error) {
      toast({
        title: "Error",
        description: `${error}`,
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box backgroundColor={"white"} p={5}>
        <FormBuscador
          handleSubmit={handleSubmit}
          btnText={"Buscar actividad"}
        />
      </Box>

      {Object.keys(newOrder).length ? (
        <>
          <Box
            backgroundColor={"white"}
            p={5}
            my={5}
            maxWidth={["88vw", "100vw"]}
            overflowX={"auto"}
          >
            <ClasesList clases={newOrder.clases} />
            <CorreccionesList acuerdos={newOrder.acuerdos} />
            <ServiciosList servicios={newOrder.especiales} />

            <Heading as={"h2"} size={"lg"} my={5}>
              Importe total a facturar
            </Heading>
            <Text fontSize={24} fontWeight={"bold"}>
              {newOrder.idPaisProfesor == 1 ? `$ ${newOrder.importeTotal}` : `${newOrder.importeTotal} USD`}
            </Text>

            <Button colorScheme={"green"} mt={5} onClick={onOpen}>
              Generar
            </Button>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>¿Estás seguro?</ModalHeader>
              <ModalCloseButton />

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={addOrder}
                  isLoading={loadingGenerateOrderForm}
                >
                  Confirmar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
