import { useDisclosure, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import axonApi from "../../../Api/axonApi";
import { ObservadosContext } from "../../../Context/requisitos/observados/ObservadosContext";

export const useObservado = (idObservado: string) => {
  const { updateObservadoState } = useContext(ObservadosContext);
  const toast = useToast();
  const [optionSelected, setOptionSelected] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonsOptions: {
    buttonText: string;
    colorScheme: string;
    state: number;
  }[] = [
    { colorScheme: "green", buttonText: "Aceptar", state: 1 },
    { colorScheme: "red", buttonText: "Rechazar", state: 2 },
  ];

  const aceptarProfesor = async () => {
    setLoading(true);
    const aceptaProfesor = buttonsOptions[optionSelected].state;

    const { data } = await axonApi.put(
      `/master/profesores/requisitos/observados/${idObservado}/aceptar`,
      { aceptaProfesor }
    );

    toast({
      title:
        data.type === "ok"
          ? aceptaProfesor === 1
            ? "Aceptado"
            : "Rechazado"
          : "Hubo un error!",
      status: data.type === "ok" ? "success" : "error",
      isClosable: true,
      duration: 3000,
    });

    if (data.type === "ok") {
      updateObservadoState(idObservado, aceptaProfesor);
    }

    setLoading(false);
    onClose();
  };

  return {
    loading,
    isOpen,
    buttonsOptions,
    optionSelected,
    setOptionSelected,
    aceptarProfesor,
    onOpen,
    onClose,
  };
};
