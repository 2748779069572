import { ObservadoProvider } from "../../../Context/requisitos/observados/ObservadoContext";
import { Observado } from "./Observado";

export const ObservadoPage = () => {
  return (
    <ObservadoProvider>
      <Observado />
    </ObservadoProvider>
  );
};
