import { ChangeEvent, useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

export const useLogin = (docente: boolean) => {
  const toast = useToast();
  const [error, setError] = useState({ message: "" });
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const ingresar = async () => {
    const res = await login();
    if (res.type === "error") {
      setError({ message: res.message });
    } else {
      window.location.reload();
    }
  };

  const login = async () => {
    try {
      const res = await axios.post(
        "https://api.axontraining.com/system/login",
        data
      );

      const token = docente ? "token" : "token_empleados";

      setCookie(token, res.data.token, 1);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.token;

      const res2 = await axios.get(
        "https://api.axontraining.com/master/sesion"
      );

      return res2.data;
    } catch (error: any) {
      if (error.request.status === 401) {
        return {
          type: "error",
          message: error.response.data.message,
        };
      }
      return {
        type: "error",
        message: "Ha habido un error.",
      };
    }
  };

  const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();

    if (process.env.NODE_ENV !== "development") {
      document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        expires +
        ";domain=.axontraining.com;path=/";
    } else {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  };

  const handleForgetPassword = async () => {
    const { email } = data;
    try {
      const response = await axios.post(
        "https://api.axontraining.com/master/recuperar-pass-enviar-email",
        {
          email: email,
        }
      );
      if (response) {
        toast({
          title: "Exito",
          description:
            "Se envio un mail a tu casilla, por favor revisa para poder continuar con el cambio de contraseña",
          status: "success",
          duration: 3500,
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3500,
        isClosable: true,
      });
    }
  };

  return { error, data, handleInputChange, ingresar, handleForgetPassword };
};
