import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import { RequisitosInterface } from "../../../Interfaces/requisitos/requisitosInterface";
import { Table } from "./Table";
interface Props {
  acuerdos: RequisitosInterface[];
}

export const AcuerdoItem = ({ acuerdos }: Props) => {
  return acuerdos && acuerdos.length ? (
    <Accordion allowMultiple>
      {acuerdos.map((acuerdo) => (
        <AccordionItem key={acuerdo.idCurso} p={5}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="center">
                {acuerdo.alias}
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Table requisitos={acuerdo.requisitos} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  ) : (
    <Alert status="info" variant={"left-accent"} mt={5}>
      <AlertIcon />
      No tienes correcciones pendientes
    </Alert>
  );
};
