import { useReducer, useState } from "react";
import axonApi from "../../Api/axonApi";
import { MaterialesInterface } from "../../Interfaces/formaciones/materialesInterface";
import {
  AsistenciasAlumnosInterface,
  ClasesInterface,
} from "../../Interfaces/formacionesInterface";
import { CursosInterface } from "../../Interfaces/sessionInterface";
import { formacionReducer } from "./formacionReducer";

const initialValues = {
  clases: [],
  alumnos: [],
  materiales: [],
};

const materialLinks: { [k: string]: string } = {
  2: "https://trello.com/b/l8uavCqq/estructura-de-clase-peo",
  5: "https://trello.com/b/xis2ibsU/i-etapa-coaching-laboral",
  20: "https://trello.com/b/P13DcgA8/segunda-etapa-ontologico",
  86: "https://trello.com/b/hokYRRlI/planificaci%C3%B3n-fla-2022",
};

export const useFormacion = (formacion: CursosInterface) => {
  const [state, dispatch] = useReducer(formacionReducer, initialValues);
  const [isLoading, setIsLoading] = useState(true);

  const chargeData = async () => {
    if (Object.values(state).some((value) => !value.length)) {
      const clases = getClases();
      const alumnos = getAlumnos();
      const materiales = getMateriales();

      await Promise.allSettled([clases, alumnos, materiales]);

      setIsLoading(false);
    }
  };

  const getClases = async () => {
    const { data } = await axonApi.get<ClasesInterface[]>(
      `/master/profesores/clases?idCursoAca=${formacion.id}`
    );
    dispatch({ type: "setClases", payload: data });
  };

  const getAlumnos = async () => {
    const { data } = await axonApi.get<AsistenciasAlumnosInterface[]>(
      `/master/profesores/cursos/${formacion.id}/asistencias?claseInicio=1&claseFin=32`
    );
    dispatch({ type: "setAlumnos", payload: data });
  };

  const getMateriales = async () => {
    if (formacion.grupo === "1") {
      const idServicio =
        parseInt(formacion.idServicio) === 3 ? 20 : formacion.idServicio;
      const material = [{ linkMaterial: materialLinks[idServicio] }];
      dispatch({ type: "setMateriales", payload: material });
    } else {
      const { data } = await axonApi.get<MaterialesInterface[]>(
        `/academico/aulas/${formacion.id}/materiales`
      );
      dispatch({ type: "setMateriales", payload: data });
    }
  };

  return {
    ...state,
    isLoading,
    chargeData,
  };
};
