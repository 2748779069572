import { PermisosData, Session } from "../../Interfaces/sessionInterface";
import { SessionInterface } from "./SessionContext";

export type ActionType =
  | { type: "setSession"; payload: { session: Session | null } }
  | { type: "setPermisos"; payload: PermisosData }
  | { type: "stopLoader" }
  | { type: "logout" };

export const sessionReducer = (state: SessionInterface, action: ActionType) => {
  switch (action.type) {
    case "setSession":
      return {
        ...state,
        session: action.payload.session,
        isLoading: false,
      };

    case "setPermisos":
      return { ...state, permisos: action.payload, isLoading: false };

    case "stopLoader":
      return { ...state, isLoading: false };

    case "logout":
      return { ...state, token: "", session: null, isLoading: false };
    default:
      return { ...state };
  }
};
