import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useObservado } from "../../../Hooks/Requisitos/Observados/useObservado";
import { ObservadoItemInterface } from "../../../Interfaces/requisitos/observadosInterface";

type Props = {
  observado: ObservadoItemInterface;
  aceptaProfesor?: boolean;
};

export const Row = ({ observado, aceptaProfesor = false }: Props) => {
  const {
    isOpen,
    loading,
    buttonsOptions,
    optionSelected,
    setOptionSelected,
    aceptarProfesor,
    onOpen,
    onClose,
  } = useObservado(observado.id);

  return (
    <>
      <Tr key={observado.id}>
        <Td>{observado.titulo}</Td>
        <Td>
          {observado.alumno.nombre} {observado.alumno.apellido}
        </Td>
        <Td>{observado.metodo}</Td>
        <Td>{observado.fechaVencimiento}</Td>
        <Td>{observado.aula.alias}</Td>
        <Td>{observado.estado.orientacion}</Td>
        <Td>
          {aceptaProfesor ? (
            <VStack>
              {buttonsOptions.map((button, key) => (
                <Button
                  key={key}
                  colorScheme={button.colorScheme}
                  onClick={() => {
                    setOptionSelected(key);
                    onOpen();
                  }}
                >
                  {button.buttonText}
                </Button>
              ))}
            </VStack>
          ) : (
            <Link to={`${observado.id}`}>
              <Button colorScheme={"blue"}>Revisar</Button>
            </Link>
          )}
        </Td>
      </Tr>

      {aceptaProfesor && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {buttonsOptions[optionSelected].buttonText} Observado
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody></ModalBody>

            <ModalFooter>
              <Button
                isLoading={loading}
                colorScheme={"blue"}
                onClick={aceptarProfesor}
              >
                {buttonsOptions[optionSelected].buttonText}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
