import { useToast } from "@chakra-ui/react";
import { createContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axonApi from "../../../Api/axonApi";
import {
  Competencia,
  CorreccionObservado,
  Estado,
  ObservadoCorreccionInterface,
} from "../../../Interfaces/requisitos/observadosInterface";
import { observadoReducer } from "./observadoReducer";

export interface ObservadoInterface {
  observado: ObservadoCorreccionInterface;
  correccion: CorreccionObservado;
  estados: Estado[];
  competencias: Competencia[];
  isLoading: boolean;
  corrigeExamen: boolean;
  sendCorreccion: (values: { [k: string]: any }) => void;
}

export const ObservadoContext = createContext({} as ObservadoInterface);

const initialState = {
  observado: {} as ObservadoCorreccionInterface,
  correccion: {} as CorreccionObservado,
  estados: [],
  competencias: [],
  isLoading: true,
  corrigeExamen: true,
  sendCorreccion: () => {},
};

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const ObservadoProvider = ({ children }: Props) => {
  const { idObservado } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [state, dispatch] = useReducer(observadoReducer, initialState);
  useEffect(() => {
    dispatch({ type: "resetData" });
    const getData = async () => {
      const { data } = await axonApi.get<ObservadoCorreccionInterface>(
        `/master/alumnos/requisitos/observados/${idObservado}`
      );

      if (data.feedBackTextoJson) {
        const correccion = JSON.parse(data.feedBackTextoJson);
        dispatch({ type: "setCorreccion", payload: correccion });
      } else {
        await Promise.all([getCompetencias(), getEstados()]);
      }
      dispatch({ type: "setObservado", payload: data });
    };

    getData();
    // eslint-disable-next-line
  }, [idObservado]);

  const getCompetencias = async () => {
    const { data } = await axonApi.get<Competencia[]>(
      `/master/profesores/requisitos/observados/${idObservado}/competencias`
    );
    dispatch({ type: "setCompetencias", payload: data });
  };

  const getEstados = async () => {
    const { data } = await axonApi.get<Estado[]>(
      "/master/profesores/requisitos/estados"
    );
    const formattedData = data.filter((estado) =>
      ["2", "3", "6", "11"].includes(estado.id)
    );
    dispatch({ type: "setEstados", payload: formattedData });
  };

  const sendCorreccion = async (values: { [k: string]: any }) => {
    const formattedData = { ...values };
    formattedData.estado = {
      id: formattedData.estado,
      nombre: state.estados.filter(
        (estado) => estado.id === formattedData.estado
      )[0].nombre,
    };
    try {
      await axonApi.put(
        `/master/profesores/requisitos/observados/${idObservado}`,
        {
          form: JSON.stringify(formattedData),
          alumno: state.observado.alumno.idAlumno,
        }
      );

      toast({
        title: "Revisado!",
        description: "Práctica Revisada",
        status: "success",
        isClosable: true,
        duration: 5000,
      });
      navigate("/requisitos/observados");
    } catch (error) {
      toast({
        title: "Error",
        description: "No se pudo corregir la práctica",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  return (
    <ObservadoContext.Provider value={{ ...state, sendCorreccion }}>
      {children}
    </ObservadoContext.Provider>
  );
};
