import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  margin: 20px 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
