import React, { useEffect } from "react";

export const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  handler: () => void,
  enabled: boolean
) => {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      const event = e.target as HTMLDivElement;
      if (
        enabled &&
        ref.current &&
        !ref.current.contains(event) &&
        !event.id.includes("burguer-menu")
      ) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, enabled, handler]);
};
