import { ClasesProvider } from "../../Context/clases/ClasesContext";
import { Clases } from "./index";

export const ClasesPage = () => {
  return (
    <ClasesProvider>
      <Clases />
    </ClasesProvider>
  );
};
