import { PostulacionesProvider } from "../../../Context/docentes/postulaciones/postulacionesContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { Postulaciones } from "./Postulaciones";

export const PostulacionesPage = () => {
  return (
    <PostulacionesProvider>
      <MainTitle title="Postularme" />

      <Postulaciones />
    </PostulacionesProvider>
  );
};
