import {
  FaArchive,
  FaChartBar,
  FaClipboardList,
  FaHome,
  FaUserGraduate,
} from "react-icons/fa";
import { MdBook } from "react-icons/md";
import { SidebarData } from "../../Interfaces/sessionInterface";
import { GiBookshelf, GiTeacher } from "react-icons/gi";

export const data: SidebarData[] = [
  {
    name: "Home",
    icon: FaHome,
    path: "/inicio",
  },
  {
    name: "Clases",
    icon: MdBook,
    path: "/clases",
  },
  {
    name: "Perfil",
    icon: FaUserGraduate,
    path: "/perfil",
  },
  {
    name: "Formaciones",
    icon: FaChartBar,
    path: "/formaciones",
  },
  {
    name: "Materiales",
    icon: GiBookshelf,
    path: "/materiales",
  },
  {
    name: "Docentes",
    icon: GiTeacher,
    path: "/docentes",
    children: [
      {
        name: "Postulaciones",
        path: "/postulaciones",
      },
      {
        name: "Orden de Pago",
        path: "/orden-de-pago",
      },
    ],
  },
  {
    name: "Requisitos",
    icon: FaClipboardList,
    path: "/requisitos",
    children: [
      {
        name: "Acuerdos",
        path: "/acuerdos",
      },
      {
        name: "Prácticas",
        path: "/practicas",
      },
      {
        name: "Observados",
        path: "/observados",
      },
    ],
  },
  {
    name: "Postulaciones a staff",
    icon: FaArchive,
    path: "/postulaciones-staff",
  },
];
