import * as Yup from "yup";

export const validation = Yup.object().shape({
  pregunta1: Yup.string().required("Pregunta requerida"),
  pregunta2: Yup.string().required("Pregunta requerida"),
  pregunta3: Yup.string().required("Pregunta requerida"),
  pregunta4: Yup.string().required("Pregunta requerida"),
  pregunta5: Yup.string().required("Pregunta requerida"),
  pregunta6: Yup.string().required("Pregunta requerida"),
  pregunta7: Yup.string().required("Pregunta requerida"),
});
