import React, { useContext } from 'react'

import { WrapperSidebarContext } from '../wrapperSidebarContext'
import * as S from './BurguerMenu.styles'

const BurguerMenu = () => {
  const { toggleSidebar } = useContext(WrapperSidebarContext)

  return (
    <S.BurguerMenu id='burguer-menu' onClick={() => toggleSidebar()}>
      <S.BurguerMenuLine id='burguer-menu__line-1'></S.BurguerMenuLine>
      <S.BurguerMenuLine id='burguer-menu__line-2'></S.BurguerMenuLine>
      <S.BurguerMenuLine id='burguer-menu__line-3'></S.BurguerMenuLine>
    </S.BurguerMenu>
  )
}

export default BurguerMenu
