import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TabPanel,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { ObservadoItemInterface } from "../../../Interfaces/requisitos/observadosInterface";
import { Row } from "./Row";

type Props = {
  observados: ObservadoItemInterface[];
  bgColor: string;
  aceptaProfesor?: boolean;
};

export const TableObservados = ({
  observados,
  aceptaProfesor = false,
}: Props) => {
  return (
    <TabPanel>
      {observados ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Coaching Observado</Th>
              <Th>Alumno</Th>
              <Th>Revisión</Th>
              <Th>Vencimiento</Th>
              <Th>Aula</Th>
              <Th>Orientación</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {observados.map((observado) => (
              <Row
                key={observado.id}
                observado={observado}
                aceptaProfesor={aceptaProfesor}
              />
            ))}
          </Tbody>
        </Table>
      ) : (
        <Alert status="info" variant={"left-accent"} mt={5}>
          <AlertIcon />
          Sin resultados
        </Alert>
      )}
    </TabPanel>
  );
};
