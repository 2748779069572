import { UseDisclosureProps } from "@chakra-ui/hooks";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { useContext } from "react";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";
import { RequisitosContext } from "../../../Context/requisitos/RequisitosContext";
import { FormContainer, FormField } from "../../Layouts/Form";

import { useAcuerdo } from "../../../Hooks/Requisitos/Acuerdos/useAcuerdo";
import { SessionContext } from "../../../Context/session/SessionContext";
import { validation } from "./validation";
import { useToast } from "@chakra-ui/react";

interface Props {
  requisito: Requisito;
}

export const Detalle = ({ requisito, onClose }: Props & UseDisclosureProps) => {
  const { session } = useContext(SessionContext);
  const { estados, questions } = useContext(RequisitosContext);
  const { loading, handleSubmit } = useAcuerdo(requisito.id);
  const toast = useToast();
  const initialValues = {
    questions,
    requisito: { nombre: requisito.titulo },
    alumno: { ...requisito.alumno },
    Profesor: session?.name,
    options: [],
  };

  const onSubmit = async (values: { [k: string]: any }) => {
    try {
      await handleSubmit(values, requisito);
      toast({
        title: "Verificado",
        description: "Acuerdo Corregido",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
      if (onClose) onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "No se pudo corregir el acuerdo",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  return (
    <div>
      <HStack justifyContent={"space-between"}>
        <Text fontSize={"md"}>
          <b>Titulo: </b>
          {requisito.titulo}
        </Text>
        <Text fontSize={"md"}>
          <b>Alumno:</b> {requisito.alumno.nombre} {requisito.alumno.apellido}{" "}
        </Text>
      </HStack>
      <Flex mt={5} justifyContent={"center"}>
        {requisito.audio ? (
          <audio controls preload="none">
            <source src={requisito.audio.ubicacion} type={"audio/mpeg"} />
          </audio>
        ) : (
          "No tiene audio disponible"
        )}
      </Flex>

      <Text my={5}>
        Seleccione las opciones que considere que el alumno cumplio
        correctamente.
      </Text>

      <FormContainer
        initialValues={initialValues}
        validationSchema={validation}
        handleSubmit={(values) => onSubmit(values)}
      >
        <Box mb={5}>
          <FormField
            name="options"
            inputType="checkbox"
            options={questions.map((question) => {
              return { value: question.id, label: question.pregunta };
            })}
            flexDirection={"column"}
          />
        </Box>

        <FormField
          name="requisito.feedBackTexto"
          inputType="textarea"
          cols={30}
          resize={"none"}
          my={5}
          label={"Feedback Alumno"}
        />

        <FormField
          name="estado"
          inputType="react-select"
          options={estados.map((estado) => {
            return { value: estado.id, label: estado.nombre };
          })}
          placeholder={"Elige una opcion"}
        />

        <Box mt={5}>
          <FormField
            inputType="submit"
            colorScheme={"blue"}
            placeholder={"Enviar FeedBack"}
            justifyContent={"end"}
            isLoading={loading}
            mt={5}
          />
        </Box>
      </FormContainer>
    </div>
  );
};
