import React, { useRef, useEffect, useContext } from "react";

import BurguerMenu from "./BurguerMenu";
import LogoAxon from "./LogoAxon";
import { WrapperSidebarContext } from "../wrapperSidebarContext";
import * as S from "./Header.styles";

const Header = () => {
  const { session, isInMobile } = useContext(WrapperSidebarContext);
  const imgAvatarRef = useRef<HTMLImageElement>({} as HTMLImageElement);

  useEffect(() => {
    // Si el usuario no tiene avatar
    if (!session?.avatar)
      imgAvatarRef.current.src =
        "https://axon-campus.s3.us-east-2.amazonaws.com/otros/avatar.png";
  }, [session]);

  return (
    <S.Header>
      <BurguerMenu />
      <LogoAxon />
      {!isInMobile && (
        <img
          ref={imgAvatarRef}
          src={session?.avatar}
          style={{
            width: "2rem",
            height: "2rem",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          alt={session?.avatar}
        />
      )}
    </S.Header>
  );
};

export default Header;
