import { useReducer, createContext, useEffect } from "react";
import { sessionReducer } from "./sessionReducer";
import {
  Permisos,
  PermisosData,
  Session,
} from "../../Interfaces/sessionInterface";
import axonApi from "../../Api/axonApi";
import {
  getSession,
  normalizarJsonPermisos,
  setCookie,
} from "../../Service/AuthService";

export interface SessionInterface {
  session: Session | null;
  token: string;
  isLoading: boolean;
  permisos: PermisosData;
  logout: () => void;
}

const initialValues = {
  session: null,
  token: "",
  isLoading: true,
  permisos: {
    "26": {
      application: "PV",
      lugar: "Alumnos",
      modulo: "Administrar Alumnos",
      idaction: "26",
      description: "Editar Alumno",
    },
  },
  logout: () => {},
};

export const SessionContext = createContext({} as SessionInterface);

interface Props {
  children: JSX.Element;
}

export const SessionContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(sessionReducer, initialValues);

  useEffect(() => {
    const fetchApi = async () => {
      const session = await getSession();
      if (session) {
        dispatch({ type: "setSession", payload: { session } });
        if (Array.isArray(session.sector))
          window.location.replace("https://axontraining.com");
        const { data } = await axonApi.get<Permisos[]>(
          `/usuarios/${session.id}/acciones`
        );
        const permisos = normalizarJsonPermisos(data);
        if (permisos) dispatch({ type: "setPermisos", payload: permisos });
      }
      dispatch({ type: "stopLoader" });
    };

    if (!state.session) fetchApi();
  }, [state.session]);

  const logout = () => {
    setCookie("token", null);
    dispatch({ type: "setSession", payload: {} as SessionInterface });
    dispatch({ type: "logout" });
  };

  return (
    <SessionContext.Provider value={{ ...state, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
