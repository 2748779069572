import { ObservadosProvider } from "../../../Context/requisitos/observados/ObservadosContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { Observados } from "./Observados";

export const ObservadosPage = () => {
  return (
    <ObservadosProvider>
      <MainTitle title="Observados" />
      <Observados />
    </ObservadosProvider>
  );
};
