import React, { useContext, useRef } from 'react'

import ProfileAndLogout from './ProfileAndLogout'
import FlatOption from './FlatOption'

import { useOnClickOutside } from '../useOnClickOutside'
import { WrapperSidebarContext } from '../wrapperSidebarContext'
import * as S from './Sidebar.styles'
import AccordionOption from './AccordionOption'

const Sidebar = () => {
  const { accordionItems, hasPermiso, toggleSidebar, isSidebarOpen } = useContext(WrapperSidebarContext)
  const sidebarRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(sidebarRef, () => toggleSidebar(), isSidebarOpen)

  return (
    <S.Sidebar id='sidebar' ref={sidebarRef}>
      {/* id para poder alcanzarlo cuando se quiera animar el abrir y cerrar del sidebar */}
      <S.AccordionGroup allowMultiple>
        {/* <Option option={option} /> */}
        {accordionItems.map((option) => {
          if (!option.idPermiso || hasPermiso(option.idPermiso)) {
            return option.children ? (
              <AccordionOption accordionInfo={option} key={option.name} />
            ) : (
              <FlatOption accordionInfo={option} key={option.name} />
            )
          } else return null
        })}
      </S.AccordionGroup>
      <ProfileAndLogout />
    </S.Sidebar>
  )
}

export default Sidebar
