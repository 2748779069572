import { useContext } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PostulacionesContext } from "../../../Context/docentes/postulaciones/postulacionesContext";
import { PostulacionItem } from "./Postulacion";
import { SpinnerAxn } from "../../Layouts/SpinnerAxn";

export const Postulaciones = () => {
  const { postulaciones, isLoading } = useContext(PostulacionesContext);
  return isLoading ? (
    <SpinnerAxn />
  ) : postulaciones ? (
    <Box backgroundColor={"white"} p={2} maxW={"100vw"} overflowX={"auto"}>
      <Table>
        <Thead>
          <Tr>
            <Th>Alias</Th>
            <Th>Horario</Th>
            <Th>Dia</Th>
            <Th>Inicio</Th>
            <Th style={{textAlign: "center"}}>Formador</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {postulaciones.map((postulacion) => (
            <PostulacionItem key={postulacion.id} postulacion={postulacion} />
          ))}
        </Tbody>
      </Table>
    </Box>
  ) : (
    <Alert status="info" variant={"left-accent"} mt={5}>
      <AlertIcon />
      No se postulaciones
    </Alert>
  );
};
