import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  AsistenciasAlumnosInterface,
  EstadosAcademicosInterface,
} from "../../../Interfaces/formacionesInterface";
import { Asistencia } from "./Asistencia";

type Props = {
  alumnos: AsistenciasAlumnosInterface[];
  estadosCursada: EstadosAcademicosInterface[];
};

const LIMITE_CLASES: number = 16;

export const Alumnos = ({ alumnos, estadosCursada }: Props) => {
  const [tabsAsistencias, setTabsAsistencias] = useState<number>(1);
  const [etapa, setEtapa] = useState<number>(1);
  useEffect(() => {
    if (alumnos.length) {
      setTabsAsistencias(Math.abs(alumnos.length / LIMITE_CLASES));
    }
    if (Number(alumnos[0].numeroDeClase) > LIMITE_CLASES) {
      setEtapa(2);
    }
  }, [alumnos]);
 
  return (
    <Box textAlign={"left"} paddingX={"0 !important"}>
      <Accordion allowMultiple>
        {estadosCursada.map(
          (estado) =>
            alumnos.length && (
              <AccordionItem
                key={estado.id}
                isDisabled={
                  alumnos[0].asistencias.filter(
                    (asistencia) => asistencia.estado_aca.id === estado.id
                  ).length === 0
                }
              >
                <AccordionButton paddingX={0}>
                  <Heading size={"md"} color={"gray.600"} m={5} ml={0}>
                    Estado Académico: {estado.des}
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel paddingX={0}>
                  {tabsAsistencias < 2 ? (
                    <Box overflowX={"auto"} maxWidth={"100vw"} paddingX={0}>
                      <Asistencia alumnos={alumnos} estado={estado} etapa={etapa}/>
                    </Box>
                  ) : (
                    <Tabs
                      variant="soft-rounded"
                      colorScheme="blue"
                      paddingX={0}
                    >
                      <TabList overflowX={"auto"}>
                        <Tab>2do cuatrimestre</Tab>
                        <Tab>3er cuatrimestre</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel overflowX={"auto"} maxWidth={"100vw"}>
                          <Asistencia alumnos={alumnos} estado={estado} />
                        </TabPanel>
                        <TabPanel overflowX={"auto"} maxWidth={"100vw"}>
                          <Asistencia
                            alumnos={alumnos}
                            estado={estado}
                            limiteClases={16}
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  )}
                </AccordionPanel>
              </AccordionItem>
            )
        )}
      </Accordion>
    </Box>
  );
};
