export const sidebarTheme = {
  sidebar: {
    backgroundColor: '#2D3748',
    transition: '.4s ease 0s',
  },

  burguerMenuTransition: '.4s ease 0s',

  menuOption: {
    color: 'white',
    backgroundColor: '#5F64683D',
    borderRadius: '10px',
  },
}
