import React from "react";
import { SessionContextProvider } from "./Context/session/SessionContext";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesList from "./Routes";
import { BuscadorClasesProvider } from "./Context/buscadorClasesContext";

function App() {
  return (
    <SessionContextProvider>
      <BuscadorClasesProvider>
        <Router>
          <RoutesList />
        </Router>
      </BuscadorClasesProvider>
    </SessionContextProvider>
  );
}

export default App;
