import {
  List,
  ListIcon,
  ListItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MaterialesInterface } from "../../../Interfaces/formaciones/materialesInterface";
import { FaRegFilePdf } from "react-icons/fa";
import { LinkIcon } from "@chakra-ui/icons";

type Props = {
  materiales: MaterialesInterface[];
};

export const Materiales = ({ materiales }: Props) => {
  return !materiales[0].linkMaterial ? (
    <Table>
      <Thead>
        <Tr>
          <Th>Clase</Th>
          <Th>Nombre</Th>
          <Th>Materiales</Th>
        </Tr>
      </Thead>
      <Tbody>
        {materiales &&
          materiales.map((material) => (
            <Tr key={material.id}>
              <Td>{material.nClase}</Td>
              <Td>{material.descripcion ?? `Clase ${material.nClase}`}</Td>
              <Td>
                {material.materiales?.length ? (
                  <List>
                    {material.materiales.map((material) => (
                      <ListItem key={material.id} py={3}>
                        <ListIcon as={FaRegFilePdf} color={"red.500"} />
                        <a
                          href={material.archivo}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          {material.descripcion}
                        </a>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  "Sin materiales"
                )}
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  ) : (
    <a href={materiales[0].linkMaterial} target={"_blank"} rel={"noreferrer"}>
      Link material <LinkIcon />
    </a>
  );
};
