import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useAsistencias } from "../../../Hooks/Formaciones/useAsistencias";
import {
  AsistenciasAlumnosInterface,
  EstadosAcademicosInterface,
} from "../../../Interfaces/formacionesInterface";

type Props = {
  alumnos: AsistenciasAlumnosInterface[];
  estado: EstadosAcademicosInterface;
  limiteClases?: number;
  etapa?: number;
};

export const Asistencia = ({
  alumnos,
  estado,
  limiteClases = 0,
  etapa = 1,
}: Props) => {
  let alumnosClases: any[] = [];

if (etapa === 2) {
  alumnosClases = [...alumnos].filter(
    (asistencia) => parseInt(asistencia.numeroDeClase) > 16
  );
} else {
  alumnosClases =
    limiteClases === 0
      ? [...alumnos].filter(
          (asistencia) => parseInt(asistencia.numeroDeClase) <= 16
        )
      : [...alumnos].filter(
          (asistencia) => parseInt(asistencia.numeroDeClase) > limiteClases
        );
}

const { filtrarAsistencias } = useAsistencias(alumnosClases);


  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Alumno</Th>
          {alumnosClases.map((total, key) => (
            <Th key={key}>{total.numeroDeClase}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{filtrarAsistencias(estado)}</Tbody>
    </Table>
  );
};
