import { Button, Center } from "@chakra-ui/react";
import React from "react";
import { useLogin } from "./useLogin";
import * as S from "./Login.styles";

type Props = {
  docente?: boolean;
};

const Login = ({ docente = false }: Props) => {
  const { data, error, handleInputChange, ingresar, handleForgetPassword } =
    useLogin(docente);
  return (
    <S.Ingreso>
      <div className="wrapper fadeInDown pl-0 pr-0 mt-5">
        <div id="formContent">
          <div className="fadeIn first ">
            <Center>
              {
                <img
                  src={
                    "https://axon-tienda.s3.us-east-2.amazonaws.com/logo-azul.png"
                  }
                  id="icon"
                  alt="User Icon"
                />
              }
            </Center>
          </div>
          <hr />
          <input
            type="text"
            id="login"
            className={`fadeIn second ${error.message && "formInput-error"}`}
            name="email"
            value={data.email}
            placeholder="Email"
            onChange={handleInputChange}
          />
          <input
            type="password"
            id="password"
            className={`fadeIn third ${error.message && "formInput-error"}`}
            name="password"
            value={data.password}
            placeholder="Contraseña"
            onChange={handleInputChange}
            style={{ marginBottom: 0 }}
          />

          {error.message && (
            <div className="col-12">
              <small className="text-danger fadeIn">{error.message}</small>
            </div>
          )}

          <Button size="lg" colorScheme="blue" mt={5} mb={5} onClick={ingresar}>
            Ingresar
          </Button>
          <div id="formFooter">
            <a
              className="underlineHover"
              href="/"
              style={{ cursor: "pointer" }}
              onClick={handleForgetPassword}
            >
              ¿Olvidaste tu contraseña?
            </a>
          </div>
        </div>
      </div>
    </S.Ingreso>
  );
};

export default Login;
