import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const TableBox = styled(Box)`
  overflow-x: auto;
  width: 100vw;

  @media (min-width: 768px) {
    width: calc(100vw - 10rem);
  }
`;
