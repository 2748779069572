import {
  Button,
  ButtonProps,
  Checkbox,
  Flex,
  FlexboxProps,
  Input,
  InputProps,
  Radio,
  RadioGroup,
  Stack,
  StackDirection,
  Textarea,
  TextareaProps,
  UseCheckboxGroupProps,
} from "@chakra-ui/react";
import { FieldInputProps, FormikProps } from "formik";
import { ChangeEventHandler } from "react";

import Select from "react-select";

type Props = {
  id: string;
  isDisabled?: boolean;
  inputType?:
    | "input"
    | "file"
    | "button"
    | "react-select"
    | "radio"
    | "checkbox"
    | "cancelar"
    | "submit"
    | "submitBuscador"
    | "textarea";
  options?: { value: string; label: string }[];
  isMulti?: boolean;
  bgInput?: string;
  formFormik: FormikProps<any>;
  justifyContent?: FlexboxProps;
  flexDirection?: StackDirection;
  field: FieldInputProps<any>;
  cleanSelector?: string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
};

export const InputType = ({
  id,
  isDisabled,
  inputType,
  options,
  placeholder,
  size,
  value,
  isLoading,
  colorScheme,
  isMulti = false,
  formFormik,
  bgInput,
  type,
  justifyContent,
  flexDirection = "row",
  field,
  cleanSelector,
  handleChange,
  ...rest
}: Props &
  InputProps &
  ButtonProps &
  TextareaProps &
  UseCheckboxGroupProps) => {
  switch (inputType) {
    case "input":
      return (
        <Input
          isDisabled={isDisabled}
          {...rest}
          placeholder={placeholder}
          {...field}
          size={size}
          type={inputType}
        />
      );

    case "file":
      return (
        <Input
          isDisabled={isDisabled}
          {...rest}
          {...field}
          // onChange={({
          //   target: { files },
          // }: React.ChangeEvent<HTMLInputElement>) => {
          //   if (files) formFormik.setFieldValue(field.name, files[0]);
          // }}
          onChange={handleChange ? (event) => handleChange(event) : undefined}
          size={size}
          type={"file"}
        />
      );

    case "react-select":
      return (
        <Select
          isClearable={true}
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={options}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          {...field}
          value={options?.find((option) => option.value === field.value)}
          onChange={(option, _) => {
            if (cleanSelector) {
              formFormik.setFieldValue(cleanSelector, "0");
            }
            formFormik.setFieldValue(field.name, option?.value);
          }}
          onBlur={() => formFormik.setFieldTouched(field.name, true)}
        />
      );

    case "radio":
      return (
        <RadioGroup name={field.name} value={field.value}>
          <Stack direction={flexDirection}>
            {options &&
              options.map((option, key) => (
                <Radio key={key} onChange={field.onChange} value={option.value}>
                  {option.label}
                </Radio>
              ))}
          </Stack>
        </RadioGroup>
      );

    case "textarea":
      return <Textarea name={field.name} onChange={field.onChange} {...rest} />;

    case "checkbox":
      return (
        <Stack direction={flexDirection}>
          {options &&
            options.map((option) => (
              <Checkbox
                key={option.value}
                checked={field.value.includes(option.value)}
                defaultChecked={field.value.includes(option.value)}
                name={field.name}
                onChange={field.onChange}
                value={option.value}
              >
                {option.label}
              </Checkbox>
            ))}
        </Stack>
      );

    case "submit":
      return (
        <Flex justifyContent={justifyContent}>
          <Button
            isLoading={isLoading}
            isDisabled={!formFormik.isValid || isDisabled}
            bg={bgInput}
            colorScheme={colorScheme}
            size={size}
            type="submit"
          >
            {placeholder}
          </Button>
        </Flex>
      );

    case "cancelar":
      return (
        <Button
          isDisabled={isDisabled}
          bg={bgInput}
          colorScheme={colorScheme}
          size={size}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {placeholder}
        </Button>
      );

    case "submitBuscador":
      return (
        <Button
          isLoading={isLoading}
          isDisabled={
            (formFormik.isValid &&
              Object.keys(formFormik.values).length === 0) ||
            !formFormik.isValid
          }
          bg={bgInput}
          colorScheme={colorScheme}
          size={size}
          type="submit"
        >
          {placeholder}
        </Button>
      );

    default:
      return null;
  }
};
