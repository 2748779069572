import { useEffect, useContext } from "react";
import Wrapper from "../Components/Layouts/WrapperContent/Wrapper";
import { data } from "../Components/Layouts/SidebarContent";
import { SessionContext } from "../Context/session/SessionContext";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";
import { Home } from "../Components/Home";
import { LoginPage } from "../Components/Login";
import { Perfil } from "../Components/perfilAcademico";
import { ClasesPage } from "../Components/Clases/ClasesPage";
import { FormacionesPage } from "../Components/Formaciones/FormacionesPage";

/* Requisitos */
import {
  AcuerdosPage,
  ObservadoPage,
  ObservadosPage,
  PracticasPage,
} from "../Components/Requisitos";

/* Docentes */
import { PostulacionesPage, OrdenDePagoPage } from "../Components/Docentes";

/* Postulaciones staff */
import {
  PostulacionesStaffPage,
  PostulacionFormPage,
} from "../Components/PostulacionesStaff";
import { MaterialesPage } from "../Components/Materiales/MaterialesPage";

const RoutesList = () => {
  const { isLoading, session, permisos, logout } = useContext(SessionContext);
  const navigate = useNavigate();
  const redirectTo = (pathname: string) => {
    navigate(pathname);
  };

  useEffect(() => {
    if (!session && !isLoading) navigate("/login");
  }, [session, isLoading, navigate]);

  if (isLoading)
    return (
      <Center>
        <Spinner
          color="blue.500"
          mt="4rem"
          size="xl"
          thickness="10px"
          emptyColor="red.100"
        />
      </Center>
    );

  return (
    <Wrapper
      session={session}
      permisos={permisos}
      data={data}
      logout={logout}
      redirectTo={redirectTo}
    >
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inicio" element={<Home />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/clases" element={<ClasesPage />} />
          <Route path="/formaciones" element={<FormacionesPage />} />
          <Route path="/materiales" element={<MaterialesPage />} />
          <Route path="requisitos">
            <Route path="acuerdos" element={<AcuerdosPage />} />
            <Route path="practicas" element={<PracticasPage />} />
            <Route path="observados">
              <Route path="" element={<ObservadosPage />} />
              <Route path=":idObservado" element={<ObservadoPage />} />
            </Route>
          </Route>

          <Route path="docentes">
            <Route path="postulaciones" element={<PostulacionesPage />} />
            <Route path="orden-de-pago" element={<OrdenDePagoPage />} />
          </Route>

          <Route path="postulaciones-staff">
            <Route path="" element={<PostulacionesStaffPage />} />
            <Route path=":idFormulario" element={<PostulacionFormPage />} />
          </Route>

          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </div>
    </Wrapper>
  );
};

export default RoutesList;
