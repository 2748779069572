import { Tr, Td, Button } from "@chakra-ui/react";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";
import ModalAxon from "../../Layouts/Modal/ModalAxon";
import { Detalle } from "./Detalle";

interface Props {
  requisito: Requisito;
}

export const REQUISITO_ENTREGADO = 5;

export const RequisitoRow = ({ requisito }: Props) => {
  return (
    <Tr>
      <Td>
        {requisito.alumno.nombre} {requisito.alumno.apellido}
      </Td>
      <Td>{requisito.fechaSubidaAlumno}</Td>
      <Td>{requisito.fechaVencimiento}</Td>
      <Td>
        <ModalAxon
          title={
            parseInt(requisito.estado.id) === REQUISITO_ENTREGADO
              ? "Confirmo que la Práctica fue realizada en mi clase"
              : "Detalle de la Práctica"
          }
          size="2xl"
          trigger={
            <Button colorScheme={"blue"}>
              {parseInt(requisito.estado.id) === REQUISITO_ENTREGADO
                ? "Confirmar Revisión"
                : "Ver Práctica"}
            </Button>
          }
          render={(props) => <Detalle requisito={requisito} {...props} />}
        />
      </Td>
    </Tr>
  );
};
