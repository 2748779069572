import { Box, BoxProps } from "@chakra-ui/react";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const MainBox = ({ children, ...rest }: Props & BoxProps) => {
  return (
    <Box
      backgroundColor={"white"}
      p={5}
      my={5}
      maxWidth={["88vw", "100vw"]}
      overflowX={"auto"}
      {...rest}
    >
      {children}
    </Box>
  );
};
