import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  UseDisclosureProps,
} from "@chakra-ui/react";

interface Props {
  render: (props: UseDisclosureProps) => JSX.Element;
  trigger: JSX.Element;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
  title: string;
  instaShow?: boolean;
  isDisabled?: boolean;
}

const ModalAxon = ({
  render,
  trigger,
  size = "md",
  title,
  instaShow = false,
  isDisabled = false,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={onOpen}>{trigger}</div>

      <Modal
        isCentered={true}
        closeOnOverlayClick={false}
        isOpen={(isOpen || instaShow) && !isDisabled}
        onClose={onClose}
        size={size}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{render({ isOpen, onOpen, onClose })}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAxon;
