import { useContext } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { MainBox } from "../../Layouts/MainBox";
import { SpinnerAxn } from "../../Layouts/SpinnerAxn";
import { Practicas } from "./Practicas";
import { RequisitosContext } from "../../../Context/requisitos/RequisitosContext";

export const TabsContent = () => {
  const { isLoading, tabsOptions } = useContext(RequisitosContext);

  const orderTabs = [5, 2];

  return (
    <MainBox>
      <Tabs variant={"soft-rounded"} colorScheme={"blue"}>
        <TabList>
          {orderTabs.map((tab) => (
            <Tab key={tab} isDisabled={isLoading}>
              {tabsOptions[tab].tabTitle}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {orderTabs.map((tab) => (
            <TabPanel key={tab}>
              {isLoading ? (
                <SpinnerAxn />
              ) : (
                <Practicas practicas={tabsOptions[tab].requisitos} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </MainBox>
  );
};
