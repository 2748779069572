import {
  NewOrderInterface,
  OrdenesVigentes,
  OrdenInterface,
} from "../../../Interfaces/ordenesInterface";
import { OrdenesInterface } from "./ordenesContext";

type ActionType =
  | { type: "setTabIndex"; payload: number }
  | { type: "setOrdenes"; payload: OrdenInterface[] }
  | { type: "setVigentes"; payload: OrdenesVigentes[] }
  | { type: "setNewOrder"; payload: NewOrderInterface }
  | { type: "setTypesFacturas"; payload: { id: string; tipo: string }[] }
  | { type: "loadingGenerate"; payload: boolean }
  | { type: "loadingOrders"; payload: boolean };

export const ordenesReducer = (state: OrdenesInterface, action: ActionType) => {
  switch (action.type) {
    case "setTabIndex":
      return { ...state, tabSelected: action.payload };
    case "setOrdenes":
      return { ...state, ordenes: action.payload, isLoading: false };
    case "setNewOrder":
      return { ...state, newOrder: action.payload };
    case "setVigentes":
      return { ...state, vigentes: action.payload };
    case "setTypesFacturas":
      return { ...state, tiposFacturas: action.payload };
    case "loadingGenerate":
      return { ...state, loadingGenerateOrderForm: action.payload };
    case "loadingOrders":
      return { ...state, isLoading: action.payload };

    default:
      return { ...state };
  }
};
