import React, { useState, useEffect, useContext } from "react";
import cs from "classnames";
import { AccordionIcon } from "@chakra-ui/react";

import { WrapperSidebarContext } from "../wrapperSidebarContext";
import * as S from "./AccordionOption.styles";
import { SidebarData } from "../Interfaces";

interface Props {
  accordionInfo: SidebarData;
}

const AccordionOption = ({ accordionInfo }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    redirectTo,
    hasPermiso,
    currentPathname,
    setCurrentPathname,
    toggleSidebar,
  } = useContext(WrapperSidebarContext);

  const goToSelectedPath = (path: string) => {
    const fullPathname = accordionInfo.path + path;
    setCurrentPathname(fullPathname);
    redirectTo(fullPathname);
    toggleSidebar();
  };

  useEffect(() => {
    setIsActive(false);
    currentPathname.includes(accordionInfo.path) && setIsActive(true);
  }, [currentPathname, accordionInfo.path]);

  const Icon = accordionInfo.icon;

  return (
    <S.AccordionOption className={cs({ active: isActive })}>
      <S.AccordionButton>
        <S.AccordionHeader>
          <Icon />
          {accordionInfo.name}
        </S.AccordionHeader>

        <AccordionIcon />
      </S.AccordionButton>

      <S.AccordionPanel>
        {accordionInfo.children &&
          accordionInfo.children.map(
            (child, key) =>
              (!child.idPermiso || hasPermiso(child.idPermiso)) && (
                <span
                  key={key}
                  style={{ cursor: "pointer", fontSize: ".8rem" }}
                  onClick={() => goToSelectedPath(child.path)}
                >
                  {child.name}
                </span>
              )
          )}
      </S.AccordionPanel>
    </S.AccordionOption>
  );
};

export default AccordionOption;
