import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axonApi from "../../Api/axonApi";

export const usePostulacion = (idFormulario: string | undefined) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const preguntas: { [k: string]: string } = {
    pregunta1: "¿Ha participado activamente en las clases?",
    pregunta2: "¿Sus aportes fueron acordes al tema trabajado?",
    pregunta3: "¿Su trato, con compañeros y docentes, fue respetuoso?",
    pregunta4: "¿Se interesó por practicar conversaciones en las clases?",
    pregunta5: "¿Brindaba feedback sobre conversaciones a sus compañeros?",
    pregunta6: "¿Lo recomendarías para formar parte del equipo de staff?",
    pregunta7: "¿Algún comentario?",
  };
  const handleSubmit = async (values: { [k: string]: string }) => {
    setIsLoading(true);
    let cuestionario: { [k: string]: { Q: string; A: string } } = {};
    for (const key in values) {
      cuestionario[key] = { Q: preguntas[key], A: values[key] };
    }

    const { data } = await axonApi.post(
      `/usuarios/staffes/postulacion/${idFormulario}/`,
      cuestionario
    );

    toast({
      title: data.type === "success" ? "Cuestionario Enviado" : "Error",
      description: data.msg,
      status: data.type,
      isClosable: true,
      duration: 5000,
    });

    setIsLoading(false);

    if (data.type === "success") {
      navigate("/postulaciones-staff");
    }
  };

  return { isLoading, preguntas, handleSubmit };
};
