import { Button, List, ListItem, Td, Tr } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PostulacionStaffInfo } from "../../Interfaces/postulacionesStaffesInterface";

type Props = {
  postulacion: PostulacionStaffInfo;
};

export const PostulacionItem = ({ postulacion }: Props) => {
  const getCuestionario = (postulacion: string) => {
    const cuestionario = JSON.parse(postulacion).Campus[0];
    return Object.values(cuestionario).map((item: any, key) => (
      <ListItem listStyleType={"none"} key={key}>
        <b>{item.Q}:</b> {item.A}
      </ListItem>
    ));
  };
  return (
    <Tr>
      <Td>{postulacion.alias}</Td>
      <Td>
        {postulacion.nombre} {postulacion.apellido}
      </Td>
      <Td>
        <List>{getCuestionario(postulacion.cuestionario)}</List>
      </Td>
      <Td>
        <Link to={`${postulacion.id}`}>
          <Button colorScheme={"blue"} size={"sm"}>
            Completar cuestionario
          </Button>
        </Link>
      </Td>
    </Tr>
  );
};
