import { Button } from "@chakra-ui/button";
import { Tr, Td } from "@chakra-ui/table";
import { Tooltip } from "@chakra-ui/tooltip";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";
import ModalAxon from "../../Layouts/Modal/ModalAxon";
import { Detalle } from "./Detalle";

interface Props {
  requisito: Requisito;
}

const REQUISITO_ENTREGADO = 5;

export const RequisitoRow = ({ requisito }: Props) => {
  return (
    <Tr>
      <Td>
        {requisito.alumno.nombre} {requisito.alumno.apellido}
      </Td>
      <Td>{requisito.fechaSubidaAlumno}</Td>
      <Td>{requisito.fechaVencimiento}</Td>
      <Td>
        {parseInt(requisito.estado.id) === REQUISITO_ENTREGADO ? (
          <ModalAxon
            title="Datos del acuerdo"
            size="2xl"
            trigger={<Button colorScheme={"blue"}>Revisar</Button>}
            render={(props) => <Detalle requisito={requisito} {...props} />}
          />
        ) : (
          <Tooltip
            label={!requisito.PDF ? "No disponible" : ""}
            aria-label="top"
          >
            <a
              href={requisito.PDF || undefined}
              target={"_blank"}
              rel="noreferrer"
            >
              <Button colorScheme={"blue"} isDisabled={!requisito.PDF}>
                Ver PDF
              </Button>
            </a>
          </Tooltip>
        )}
      </Td>
    </Tr>
  );
};
