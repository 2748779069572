import { Table as ChakraTable, Thead, Tr, Th, Tbody } from "@chakra-ui/react";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";
import { MainBox } from "../../Layouts/MainBox";
import { RequisitoRow } from "./RequisitoRow";
interface Props {
  requisitos: Requisito[];
}
export const Table = ({ requisitos }: Props) => {
  return (
    <MainBox>
      <ChakraTable variant="simple">
        <Thead>
          <Tr>
            <Th>Alumno</Th>
            <Th>Fecha subida</Th>
            <Th>F. Vencimiento</Th>
            <Th>Revisar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {requisitos.map((requisito) => (
            <RequisitoRow key={requisito.id} requisito={requisito} />
          ))}
        </Tbody>
      </ChakraTable>
    </MainBox>
  );
};
