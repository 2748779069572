import { useContext } from "react";
import * as Yup from "yup";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { CursosList } from "./CursosList";
import { BuscadorCursos } from "../../Layouts/BuscadorCursos";
import { BuscadorClasesContext } from "../../../Context/buscadorClasesContext";
import { MainTitle } from "../../Layouts/MainTitle";
const validation = Yup.object().shape({
  //   idServicio: Yup.number().required(),
});

export const ResumenPage = () => {
  const { isLoading, errors, cursos } = useContext(BuscadorClasesContext);

  return (
    <div>
      <MainTitle title="Resúmen de Requisitos" />
      <BuscadorCursos
        initialValues={{
          grupo: "1",
          etapa: 2,
        }}
        validation={validation}
        group={{
          options: [
            { value: "1", label: "Carrera" },
            { value: "5", label: "Posgrados" },
          ],
          isDisabled: true,
        }}
        servicesOptions={[
          { value: "20", label: "Coaching Ontologico" },
          { value: "86", label: "Coaching Laboral" },
        ]}
      />
      {!isLoading ? (
        cursos && cursos.length ? (
          <CursosList cursos={cursos} />
        ) : (
          errors && (
            <Alert status="info" variant={"left-accent"} mt={5}>
              <AlertIcon />
              No tienes carreras disponibles
            </Alert>
          )
        )
      ) : null}
    </div>
  );
};
