import { Badge, Button, Td, Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import axonApi from "../../../Api/axonApi";
import { ClasesInterface } from "../../../Interfaces/formacionesInterface";

type Props = {
  clase: ClasesInterface;
};

type linkClassProps = {
  [k: string]: JSX.Element;
};

export const Clase = ({ clase }: Props) => {
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      const { data } = await axonApi.get(`/master/usuarios/clases/${clase.id}`);
      window.open(data.linkZoom, "_blank");
    } catch (error) {
      toast({
        title: "Error",
        status: "error",
        description: "No se pudo obtener el link de la clase",
      });
    }

    setLoading(false);
  };
  const linkClass: linkClassProps = {
    "no-iniciada": <Badge>No iniciada</Badge>,
    "debe-iniciar": <Badge colorScheme={"green"}>Por iniciar</Badge>,
    "finalizada-sin-grabacion": <Badge>Clase finalizada</Badge>,
    "finalizada-con-grabacion": (
      <Button
        colorScheme={"blue"}
        isLoading={loading}
        onClick={() => handleClick()}
      >
        Ver Grabacion
      </Button>
    ),
    error: <Badge colorScheme={"red"}>Error</Badge>,
    expirado: <Badge colorScheme={"red"}>Grabación Expirada</Badge>,
  };

  return (
    <Tr>
      <Td>{clase.nombreClase}</Td>
      <Td>{clase.fecha}</Td>
      <Td>{clase.asistentes}</Td>
      <Td>{linkClass[clase.estadoInicio.estado]}</Td>
    </Tr>
  );
};
