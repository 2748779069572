import { Button, ButtonGroup } from "@chakra-ui/react";
import { FormContainer, FormField } from "../Layouts/Form";
import * as S from "./FormCopyMateriales.styled";
import * as Yup from "yup";
import ModalAxon from "../Layouts/Modal/ModalAxon";
import { CursosAcademico } from "../../Interfaces/materiales/materialesInterface";
import { useModalMaterial } from "../../Hooks/Materiales/useModalMaterial";
interface Props {
  cursoActual: CursosAcademico;
}

export const FormCopyMateriales = ({ cursoActual }: Props) => {
  const { isLoading, replaceMaterial } = useModalMaterial({
    idCursoAca: cursoActual.id,
    idClase: "",
  });
  const validation = Yup.object().shape({
    cursoSeleccionado: Yup.string().required("Seleccione un curso"),
  });

  const cursoSeleccionado = (idCurso: string) =>
    cursoActual?.restAulas?.filter((opt) => opt.value === idCurso)[0].label;

  const handleCopy = async (cursoElegido: string) => {
    try {
      await replaceMaterial(cursoElegido);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer
      initialValues={{ cursoSeleccionado: "" }}
      validationSchema={validation}
      handleSubmit={() => {}}
      render={(values) => (
        <S.Container>
          <b>Basado en</b>
          <FormField
            name="cursoSeleccionado"
            inputType="react-select"
            options={cursoActual.restAulas}
            placeholder={"Selecciona aula"}
          />
          <ModalAxon
            render={({ onClose }) =>
              values.cursoSeleccionado && (
                <>
                  <p>
                    Se eliminarán por completo todos los materiales que hayan de{" "}
                    {cursoActual.alias} (ID Aula: {cursoActual.id}) y serán
                    reemplazados en su totalidad por los de{" "}
                    {cursoSeleccionado(values.cursoSeleccionado)} (ID Aula:{" "}
                    {values.cursoSeleccionado})
                  </p>
                  <ButtonGroup py={5} justifyContent={"end"} width={"100%"}>
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button
                      colorScheme={"blue"}
                      onClick={() => handleCopy(values.cursoSeleccionado)}
                      isLoading={isLoading}
                    >
                      Si, continuar!
                    </Button>
                  </ButtonGroup>
                </>
              )
            }
            trigger={
              <Button
                colorScheme={"blue"}
                isDisabled={!values.cursoSeleccionado}
              >
                Copiar
              </Button>
            }
            title={`¿Estás segur@?`}
          />
        </S.Container>
      )}
    />
  );
};
