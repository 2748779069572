import React from "react";
import { Educacion } from "../../Interfaces/perfilAcademicoInterface";
import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
type Props = {
  educacion: Educacion;
  position: number;
  handleInformation: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "educacion",
    key: number
  ) => void;
  deleteItem: (type: "educacion", key: number) => void;
};

export const EducacionItem = ({
  educacion,
  position,
  handleInformation,
  deleteItem,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteEducation = async () => {
    await deleteItem("educacion", position);
    onClose();
  };
  return (
    <Tr>
      <Td>
        <Input
          name="institucion"
          value={educacion.institucion}
          onChange={(e) => handleInformation(e, "educacion", position)}
        />
      </Td>
      <Td>
        <Input
          name="carrera"
          value={educacion.carrera}
          onChange={(e) => handleInformation(e, "educacion", position)}
        />
      </Td>
      <Td>
        <Input
          type={educacion.encurso ? 'text' :"number"}
          disabled={educacion.encurso}
          name="fecha"
          value={educacion.fecha}
          onChange={(e) => handleInformation(e, "educacion", position)}
        />
      </Td>
      <Td>
        <IconButton
          size={"sm"}
          colorScheme={"red"}
          aria-label="Borrar"
          icon={<FaTrash />}
          onClick={() => onOpen()}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Eliminar estudio</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Un estudio eliminado no se puede recuperar</ModalBody>
            <ModalFooter>
              <Button colorScheme={"red"} onClick={() => deleteEducation()}>
                Eliminar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};
