import styled from 'styled-components'

export const BurguerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 2rem;
  height: 1.2rem;
  cursor: pointer;
`
export const BurguerMenuLine = styled.div`
  width: 100%;
  border: 1px solid #fff;
  transition: ${(props) => props.theme.burguerMenuTransition};
`
