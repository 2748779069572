import React from 'react'

type Props = {
  width?: string
}

const LogoAxon = ({ width = '5rem' }: Props) => {
  return (
    <img
      style={{ width }}
      src='https://axon-campus.s3.us-east-2.amazonaws.com/otros/axon_logo_blanco.png'
      alt='Logotipo de axon training'
    />
  )
}

export default LogoAxon
