import React, { useState, useEffect, useContext } from "react";
import cs from "classnames";
import { MdNavigateNext } from "react-icons/md";

import { WrapperSidebarContext } from "../wrapperSidebarContext";
import * as S from "./FlatOption.styles";
import { SidebarData } from "../Interfaces";

interface Props {
  accordionInfo: SidebarData;
}

const FlatOption = ({ accordionInfo }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const { redirectTo, currentPathname, setCurrentPathname, toggleSidebar } =
    useContext(WrapperSidebarContext);

  const goToSelectedPath = () => {
    const nextRoute = accordionInfo.path;
    setCurrentPathname(nextRoute);
    redirectTo(nextRoute);
    toggleSidebar();
  };

  useEffect(() => {
    setIsActive(false);
    currentPathname.includes(accordionInfo.path) && setIsActive(true);
  }, [accordionInfo.path, currentPathname]);

  const Icon = accordionInfo.icon;

  return (
    <S.FlatOption
      onClick={() => goToSelectedPath()}
      className={cs({ active: isActive })}
    >
      <S.Content>
        <Icon />
        {accordionInfo.name}
      </S.Content>
      <MdNavigateNext size="1.3rem" />
    </S.FlatOption>
  );
};

export default FlatOption;
