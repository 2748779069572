import { useContext } from "react";
import { Box } from "@chakra-ui/react";
import { ObservadoContext } from "../../../../Context/requisitos/observados/ObservadoContext";
import { FormContainer, FormField } from "../../../Layouts/Form";
import { MainBox } from "../../../Layouts/MainBox";
import { Competencias } from "./Competencias";
import { validation } from "./validation";

export const Correccion = () => {
  const { competencias, estados, sendCorreccion } =
    useContext(ObservadoContext);

  const competenciasCopy = [...competencias];

  return (
    <FormContainer
      initialValues={{
        competencias: competenciasCopy,
        observacionFinal: "",
      }}
      validationSchema={validation}
      handleSubmit={(values) => {
        sendCorreccion(values);
      }}
    >
      <Competencias competencias={competenciasCopy} />
      <MainBox>
        <FormField
          isRequired
          label="Observacion Final"
          name="observacionFinal"
          inputType="textarea"
          placeholder="Fundamente su Elección"
          cols={30}
          rows={10}
          resize={"none"}
        />
      </MainBox>
      <Box p={5} backgroundColor={"white"} mb={5}>
        <FormField
          isRequired
          name="estado"
          inputType="react-select"
          options={estados.map((estado) => {
            return { value: estado.id, label: estado.nombre };
          })}
          label={"Estado de la Revisión"}
          placeholder={"Seleccionar"}
        />
      </Box>
      <FormField
        inputType="submit"
        placeholder="Enviar Evaluación"
        colorScheme={"blue"}
        justifyContent={"center"}
      />
    </FormContainer>
  );
};
