import {
  Competencia,
  CorreccionObservado,
  Estado,
  ObservadoCorreccionInterface,
} from "../../../Interfaces/requisitos/observadosInterface";
import { ObservadoInterface } from "./ObservadoContext";

export type ActionType =
  | { type: "setObservado"; payload: ObservadoCorreccionInterface }
  | { type: "setCorreccion"; payload: CorreccionObservado }
  | { type: "setEstados"; payload: Estado[] }
  | { type: "setCompetencias"; payload: Competencia[] }
  | { type: "resetData" };

export const observadoReducer = (
  state: ObservadoInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setObservado":
      return { ...state, observado: action.payload, isLoading: false };
    case "setCorreccion":
      return { ...state, correccion: action.payload, corrigeExamen: false };
    case "setEstados":
      return { ...state, estados: action.payload };
    case "setCompetencias":
      return { ...state, competencias: action.payload };
    case "resetData":
      return { ...state, isLoading: true, corrigeExamen: true };
    default:
      return { ...state };
  }
};
