import { useEffect, useState, useContext } from "react";
import axonApi from "../../Api/axonApi";
import {
  Acreditacion,
  Educacion,
  Laboral,
  PerfilAcademicoInterface,
} from "../../Interfaces/perfilAcademicoInterface";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { SessionContext } from "../../Context/session/SessionContext";

const INITIAL_MOCK = {
  educacion: {
    idprofesor: "",
    institucion: "",
    carrera: "",
    fecha: "",
    encurso: false,
  },
  laboral: {
    idprofesor: "",
    empresa: "",
    puesto: "",
    fecha: "",
    encurso: false,
  },
};
const INITIAL_STATE = {
  acerca: "",
  objetivos: "",
  idAcreditacion: "1",
  educacion: [INITIAL_MOCK.educacion],
  laboral: [INITIAL_MOCK.laboral],
};
const validation = Yup.object().shape({
  acerca: Yup.string().required("Campo requerido"),
  objetivos: Yup.string().required("Campo requerido").typeError("objetivos"),
  idAcreditacion: Yup.string().required("Campo requerido"),
  educacion: Yup.array().of(
    Yup.object().shape({
      institucion: Yup.string().required("Campo requerido"),
      carrera: Yup.string().required("Campo requerido"),
      fecha: Yup.string().required("Campo requerido"),
    })
  ),
  laboral: Yup.array().of(
    Yup.object().shape({
      empresa: Yup.string().required("Campo requerido"),
      puesto: Yup.string().required("Campo requerido"),
      fecha: Yup.string().required("Campo requerido"),
    })
  ),
});
export const usePerfilAcademico = () => {
  const { session } = useContext(SessionContext);
  const [data, setData] = useState<PerfilAcademicoInterface>(INITIAL_STATE);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingInterface, setLoadingInterface] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [acreditaciones, setAcreditaciones] = useState<Acreditacion[]>([]);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      if (session)
        try {
          const response = await axonApi.get<PerfilAcademicoInterface>(
            `/master/profesores/${session.id}/perfil-academico`
          );
          setData({...data, ...response.data});
        } catch (error) {}
      setLoadingInterface(false);
    })();

    (async () => {
      try {
        const { data } = await axonApi.get<Acreditacion[]>(
          "/usuarios/profesores/perfil-academico/acreditaciones"
        );
        setAcreditaciones(data);
      } catch (error) {}
      setLoadingInterface(false);
    })();
  }, [session]);

  useEffect(() => {
    (async () => {
      if (await validation.isValid(data)) setDisabled(false);
    })();
  }, [data]);

  const handleChange = async ({
    target: { name, value },
  }: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    setDisabled(!value.length);
    setData({ ...data, [name]: value });
  };

  const handleInformation = (
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement>,
    type: "educacion" | "laboral",
    position: number
  ) => {
    const arrayCopy = [...data[type]].map((typeValue, key) => {
      if (key === position) {
        return { ...typeValue, [name]: value };
      } else {
        return typeValue;
      }
    });
    setDisabled(value === "");
    setData({ ...data, [type]: arrayCopy });
  };

  const addItem = (type: "educacion" | "laboral") => {
    setData({ ...data, [type]: [...data[type], INITIAL_MOCK[type]] });
  };

  const deleteItem = (type: "educacion" | "laboral", position: number) => {
    if (type === "educacion") {
      const filteredData = data.educacion.filter(
        (element: Educacion, key: number) => {
          if (key !== position) return element;
          else return null;
        }
      );
      setData({ ...data, [type]: filteredData });
    } else {
      const filteredData = data.laboral.filter(
        (element: Laboral, key: number) => {
          if (key !== position) return element;
          else return null;
        }
      );
      setData({ ...data, [type]: filteredData });
    }
  };

  const uploadProfile = async () => {
    setLoading(true);
    if (!(await validation.isValid(data))) {
      toast({
        title: "Error",
        description: "Hay errores en el formulario",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return setLoading(false);
    }
    const response = await axonApi.post(
      "/master/profesores/perfil-academico",
      data
    );
    if (response.data.type === "error") {
      toast({
        title: "Error",
        description: "Hay errores en el formulario",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Guardado",
        description: "Perfil actualizado correctamente",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return {
    data,
    isLoading,
    disabled,
    loadingInterface,
    acreditaciones,
    handleChange,
    handleInformation,
    addItem,
    deleteItem,
    uploadProfile,
  };
};
