import axios from "axios";
import {
  Permisos,
  Session,
  PermisosData,
} from "../Interfaces/sessionInterface";
import { normalize, schema } from "normalizr";
import Cookies from "js-cookie";

export function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const getSession = async () => {
  const token = `Bearer ${Cookies.get("token")}`;
  if (token) {
    try {
      const { data } = await axios.get<Session>(
        "https://api.axontraining.com/master/sesion",
        {
          headers: { Authorization: token },
        }
      );
      return data;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const normalizarJsonPermisos = (array: Permisos[]) => {
  const permiso = new schema.Entity(
    "permisos",
    {},
    { idAttribute: "idaction" }
  );
  return normalize(array, [permiso]).entities.permisos as PermisosData;
};

export const setCookie = (
  cname: string,
  cvalue: string | null,
  exdays: number = 0
) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();

  if (process.env.NODE_ENV !== "development") {
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";domain=.axontraining.com;path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
};
