import React from 'react'
import { ThemeProvider } from 'styled-components'

import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'
import { WrapperSidebarContextProvider } from './wrapperSidebarContext'
import * as S from './Wrapper.styles'
import { sidebarTheme } from './sidebarTheme'
import { PermisosData, Session, SidebarData } from './Interfaces'

type Props = {
  session: Session | null
  permisos: PermisosData
  data: SidebarData[]
  logout: () => void
  redirectTo: (pathname: string) => void
  children: JSX.Element
}

const Wrapper = ({ session, permisos, data, logout, redirectTo, children }: Props) => {
  return (
    <WrapperSidebarContextProvider
      session={session}
      permisos={permisos}
      data={data}
      logout={logout}
      redirectTo={redirectTo}
    >
      <ThemeProvider theme={sidebarTheme}>
        <S.Wrapper>
          {session ? ( // Para no renderizar el header y sidebar en la pantalla de login
            <>
              <Header />
              <Sidebar />
            </>
          ) : (
            <></>
          )}
          <S.CurrentRouteContent>{children}</S.CurrentRouteContent>
        </S.Wrapper>
      </ThemeProvider>
    </WrapperSidebarContextProvider>
  )
}

export default Wrapper
