import { useState, useContext } from "react";
import { useToast } from "@chakra-ui/react";
import axonApi from "../../Api/axonApi";
import { ModalMaterialesProps } from "../../Components/Materiales/ModalMaterial";
import { MaterialesContext } from "../../Context/materiales/materialesContext";
import {
  Materiales,
  MaterialesInterface,
} from "../../Interfaces/materiales/materialesInterface";

export const useModalMaterial = ({
  material,
  idCursoAca,
  idClase,
}: ModalMaterialesProps) => {
  const toast = useToast();
  const [file, setFile] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    updateMateriales,
    deleteMaterial: borrarMaterial,
    replaceMateriales: replaceMaterialesContext,
  } = useContext(MaterialesContext);

  const submitForm = async (values: any) => {
    if (!file && !material) {
      throw toast({
        title: "Error",
        description: "Seleccione un archivo valido",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    const formData = new FormData();
    if (file) formData.append("archivo", file);
    formData.append("data", JSON.stringify(values));

    if (!material) await addMaterial(formData);
    else await editMaterial(formData);
  };

  const addMaterial = async (formData: FormData) => {
    try {
      const { data } = await axonApi.post<Materiales>(
        `/usuarios/profesores/cursos/aulas/${idCursoAca}/clases/${idClase}/materiales`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      toast({
        title: "Material agregado",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
      updateMateriales(idCursoAca, idClase, data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw toast({
        title: "Error",
        description: "Error al agregar material",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const editMaterial = async (formData: FormData) => {
    try {
      const { data } = await axonApi.put(
        `/usuarios/profesores/cursos/aulas/${idCursoAca}/clases/${idClase}/materiales/${material?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast({
        title: "Material Editado",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
      updateMateriales(idCursoAca, idClase, data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw toast({
        title: "Error",
        description: "Error al agregar material",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleChange = (files: FileList | null) => {
    if (files) setFile(files[0]);
  };

  const replaceMaterial = async (cursoElegido: string) => {
    setIsLoading(true);
    try {
      const { data } = await axonApi.post<MaterialesInterface[]>(
        `/usuarios/profesores/cursos/aulas/${idCursoAca}/materiales/copiar`,
        { cursoElegido }
      );
      replaceMaterialesContext(data, idCursoAca);
      toast({
        title: "Materiales copiados correctamente",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw toast({
        title: "Error",
        description: "No se pudieron copiar los materiales",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const deleteMaterial = async () => {
    setIsLoading(true);
    try {
      await axonApi.delete(
        `/usuarios/profesores/cursos/aulas/${idCursoAca}/clases/${idClase}/materiales/${material?.id}`
      );
      material && borrarMaterial(idCursoAca, idClase, material.id);
      toast({
        title: "Material Borrado",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw toast({
        title: "Error",
        description: "Error al eliminar el material",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  return {
    isLoading,
    toast,
    submitForm,
    handleChange,
    replaceMaterial,
    deleteMaterial,
  };
};
