import styled from 'styled-components'
import { Button } from '@chakra-ui/react'

export const FlatOption = styled(Button)`
  display: flex;
  justify-content: space-between !important;

  color: ${(props) => props.theme.menuOption.color};
  background-color: ${(props) => props.theme.menuOption.backgroundColor} !important;
  font-weight: normal !important;

  border-radius: ${(props) => props.theme.menuOption.borderRadius};
  border: none;
  flex-shrink: 0; // No se encogera cuando sean muchos elementos en el contenedor padre

  &.active {
    color: #40a8ff;
    background-color: #194671 !important;
  }

  &:hover {
    background-color: #5f646833;
  }

  &:focus {
    box-shadow: none;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem !important;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`
