import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Especiales } from "../../../../Interfaces/ordenesInterface";

type Props = {
  servicios: Especiales[];
};

export const ServiciosList = ({ servicios }: Props) => {
  
  const formatearPrecio = (importe: string, moneda: string) => {
    let string = "";

    if(moneda == "ARS")
      string += "$ ";
      
    string += importe + " ";
    string += moneda;
    return string;
  }

  return (
    <>
      <Heading as={"h2"} size={"lg"} my={5}>
        Servicios Especiales
      </Heading>

      {servicios && (
        <Table variant={"simple"}>
          <Thead>
            <Tr>
              <Th>Fecha</Th>
              <Th>Descripción</Th>
              <Th>Importe</Th>
            </Tr>
          </Thead>
          <Tbody>
            {servicios.map((servicio) => (
              <Tr key={servicio.id}>
                <Td>{servicio.fechaClaseEspeciales}</Td>
                <Td>{servicio.descripcion}</Td>
                <Td>
                  {formatearPrecio(servicio.valor, servicio.currency)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};
