import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Clases } from "../../../../Interfaces/ordenesInterface";

type Props = {
  clases: Clases[];
};

export const ClasesList = ({ clases }: Props) => {
  return (
    <div>
      <Heading as={"h2"} size={"lg"} my={5}>
        Clases
      </Heading>

      {clases && (
        <Table variant={"simple"}>
          <Thead>
            <Tr>
              <Th>Fecha</Th>
              <Th>Aula</Th>
              <Th>Nombre Clase</Th>
              <Th>Horas</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clases.map((clase) => (
              <Tr key={clase.id}>
                <Td>{clase.fecha}</Td>
                <Td>{clase.aula}</Td>
                <Td>{clase.clase}</Td>
                <Td>{clase.horas}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </div>
  );
};
