import { useEffect, useReducer, createContext } from "react";
import axonApi from "../../Api/axonApi";
import {
  ClasesInterface,
  ProfesoresList,
} from "../../Interfaces/clasesInterface";
import { OptionsInterface } from "../../Interfaces/interfaces";
import { filtrarTimeZone, getDate } from "../../Utils/functions";
import { clasesReducer } from "./clasesReducer";

export interface ClasesContextInterface {
  clases: ClasesInterface[];
  fecha: string;
  profesores: OptionsInterface[];
  isLoading: boolean;
  calendarDate: Date;
  profesorSelected: string;
  onChangeDate: (date: Date) => void;
  updateClase: (clase: ClasesInterface) => void;
}

const initialValues = {
  clases: [],
  profesores: [],
  fecha: getDate(new Date()),
  isLoading: true,
  calendarDate: new Date(),
  profesorSelected: "",
  onChangeDate: () => {},
  updateClase: () => {},
};

export const ClasesContext = createContext({} as ClasesContextInterface);

interface Props {
  children: JSX.Element;
}

export const ClasesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(clasesReducer, initialValues);

  useEffect(() => {
    fetchAPI(state.calendarDate);
    getProfesores();
  }, [state.calendarDate]);

  const fetchAPI = async (date: Date) => {
    const timeFormat = Intl.DateTimeFormat().resolvedOptions();
    if (timeFormat.timeZone === undefined) {
      timeFormat.timeZone = "America/Buenos_Aires";
    }
    const timePais = timeFormat.timeZone.split("/")[1];

    const fecha = getDate(date);
    try {
      const response = await axonApi.get<ClasesInterface[]>(
        `/master/profesores/clases?desde=${fecha}&hasta=${fecha}`
      );
      let data;
      if (timeFormat.timeZone === "America/Buenos_Aires") {
        data = response.data;
      } else {
        data = response.data.map((clase) => {
          clase = {
            ...clase,
            fechaFil: filtrarTimeZone(timeFormat, clase.fecha, clase.horaInicio)
              .date,
            horaInicioFil: filtrarTimeZone(
              timeFormat,
              clase.fecha,
              clase.horaInicio
            ).time,
            horaFinFil: filtrarTimeZone(timeFormat, clase.fecha, clase.horaFin)
              .time,
            horaIniciadaFil:
              clase.horaIniciada &&
              filtrarTimeZone(timeFormat, clase.fecha, clase.horaIniciada).time,
            timePais: timePais,
          };
          return clase;
        });
      }
      dispatch({ type: "setClases", payload: data });
    } catch (error) {
      dispatch({ type: "setClases", payload: [] });
    }
  };

  const getProfesores = async () => {
    const { data } = await axonApi.get<ProfesoresList[]>(
      "/usuarios/profesores"
    );
    const opciones = data.map((profesor) => ({
      value: profesor.id,
      label: profesor.nombre,
    }));
    dispatch({ type: "setProfesores", payload: opciones });
  };

  const onChangeDate = async (date: Date) => {
    dispatch({ type: "changeDate", payload: date });
    await fetchAPI(date);
  };

  const updateClase = (clase: ClasesInterface) => {
    dispatch({ type: "updateClase", payload: clase });
  };

  return (
    <ClasesContext.Provider
      value={{
        ...state,
        onChangeDate,
        updateClase,
      }}
    >
      {children}
    </ClasesContext.Provider>
  );
};
