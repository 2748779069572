import { ClasesInterface } from "../../Interfaces/clasesInterface";
import { OptionsInterface } from "../../Interfaces/interfaces";
import { ClasesContextInterface } from "./ClasesContext";

export type ActionType =
  | { type: "setClases"; payload: ClasesInterface[] }
  | { type: "changeDate"; payload: Date }
  | { type: "setProfesores"; payload: OptionsInterface[] }
  | { type: "updateClase"; payload: ClasesInterface };

export const clasesReducer = (
  state: ClasesContextInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setClases":
      return {
        ...state,
        clases: action.payload,
        isLoading: false,
      };
    case "changeDate":
      return { ...state, calendarDate: action.payload, isLoading: true };
    case "setProfesores":
      return { ...state, profesores: action.payload };
    case "updateClase":
      return {
        ...state,
        clases: state.clases.map((clase) =>
          clase.id === action.payload.id ? action.payload : clase
        ),
      };
    default:
      return { ...state };
  }
};
