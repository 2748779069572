import { createContext, useEffect, useReducer } from "react";
import { observadosReducer } from "./observadosReducer";
import axonApi from "../../../Api/axonApi";
import { ObservadosListInterface } from "../../../Interfaces/requisitos/observadosInterface";

export interface ObservadoContextInterface {
  observados: ObservadosListInterface;
  isLoading: boolean;
  updateObservadoState: (observado: string, estado: number) => void;
}

export const ObservadosContext = createContext({} as ObservadoContextInterface);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const initialState = {
  observados: {} as ObservadosListInterface,
  isLoading: true,
  updateObservadoState: (observado: any) => {},
};

export const ObservadosProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(observadosReducer, initialState);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axonApi.get<ObservadosListInterface>(
          `/master/profesores/v2/requisitos/observados`
        );
        dispatch({ type: "setObservados", payload: data });
        dispatch({ type: "setLoading", payload: false });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const updateObservadoState = (idObservado: string, estado: number) => {
    const observadosList = { ...state.observados };
    observadosList["Aceptar"] = state.observados["Aceptar"].filter(
      (observado) => observado.id !== idObservado
    );
    if (estado === 1) {
      const observado = state.observados["Aceptar"].filter(
        (observado) => observado.id === idObservado
      )[0];
      observadosList["Pendiente de Corrección"] = [
        ...state.observados["Pendiente de Corrección"],
        observado,
      ];
    }

    dispatch({ type: "setObservados", payload: observadosList });
  };

  return (
    <ObservadosContext.Provider value={{ ...state, updateObservadoState }}>
      {children}
    </ObservadosContext.Provider>
  );
};
