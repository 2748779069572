import { Alert, AlertIcon, Skeleton, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAlertas } from "../../Hooks/Home/useAlertas";
import { useEffect, useState } from "react";
import axonApi from "../../Api/axonApi";

export const Home = () => {
  const { isLoading, alertas, infoRequisitos, formData } = useAlertas();
  const [isLoadingPostulacion, setIsLoadingPostulacion] = useState(true);
  const [postulaciones, setPostulaciones] = useState<any[]>([])

  useEffect(() => {
    (async () => {
      await axonApi.get("/master/profesores/postulaciones/postulaciones-aceptadas").then((res) => {
        setPostulaciones(res.data.postulacionesAceptadas)
        setIsLoadingPostulacion(false)
      });
    })();
  }, []);

  return (
    <>
      <Stack spacing={3} mt={3}>
        {isLoading ? (
          <Skeleton height={"48px"} />
        ) : (
          alertas?.requisitos &&
          alertas.requisitos.map((requisito, key) => (
            <Alert
              status={infoRequisitos[requisito.nombre].color}
              key={key}
              variant={"left-accent"}
            >
              <AlertIcon />
              {requisito.texto}{" "}
              <Link
                onClick={() => formData(requisito)}
                to={infoRequisitos[requisito.nombre].redireccion}
              >
                <Text fontWeight={"bold"} ml={1}>
                  Revisar
                </Text>
              </Link>
            </Alert>
          ))
        )}
      </Stack>
      <Stack spacing={3} mt={3}>
        {isLoadingPostulacion ? (
          <Skeleton height={"48px"} />
        ) : postulaciones?.length > 0 ? (
          <Alert variant={"left-accent"}>
            <AlertIcon />
            Fuiste seleccionado/a en la{" "}
            <Text fontWeight={"bold"} ml={1}>
              {postulaciones.map((postulacion) => postulacion.alias).join(", ")}
            </Text>
          </Alert>
        ) : (
          <div></div>
        )}
      </Stack>
    </>
  );
};
