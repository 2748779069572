import { useEffect, useState } from "react";
import axonApi from "../../Api/axonApi";
import { EstadosAcademicosInterface } from "../../Interfaces/formacionesInterface";

export const useFormaciones = () => {
  const [estadosAcademicos, setEstadosAcademicos] = useState<
    EstadosAcademicosInterface[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (() => {
      getEstadosAcademicos();

      setLoading(false);
    })();
  }, []);

  const getEstadosAcademicos = async () => {
    const { data } = await axonApi.get<EstadosAcademicosInterface[]>(
      "/academico/aulas/estados-academicos"
    );
    setEstadosAcademicos(data);
  };

  return {
    estadosAcademicos,
    loading,
  };
};
