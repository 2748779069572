import { OptionsInterface } from "../Interfaces/interfaces";
import { CursosInterface } from "../Interfaces/sessionInterface";
import { BuscadorCursosInterface } from "./buscadorClasesContext";

type ActionType =
  | { type: "setAllServices"; payload: OptionsInterface[] }
  | { type: "setCursos"; payload: CursosInterface[] }
  | {
      type: "setServicesByGroup";
      payload: { idServicio: number; data: OptionsInterface[] };
    }
  | { type: "setLoading"; payload: boolean };

export const buscadorCursosReducer = (
  state: BuscadorCursosInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setCursos":
      return {
        ...state,
        cursos: action.payload,
        isLoading: false,
        errors: action.payload.length === 0,
      };
    case "setServicesByGroup":
      return {
        ...state,
        servicios: {
          ...state.servicios,
          [action.payload.idServicio]: action.payload.data,
        },
      };
    case "setAllServices":
      return {
        ...state,
        allServices: [...state.allServices, ...action.payload],
      };
    case "setLoading":
      return { ...state, isLoading: action.payload };
    default:
      return { ...state };
  }
};
