import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 3rem 1fr;

  width: 100%;
`

export const CurrentRouteContent = styled.div`
  width: 100%;
  height: calc(100vh - 3rem); // 3rem porque es el alto que tiene el header en 'grid-template-rows'
  padding: 0 2rem;
`
