import * as yup from "yup";

export const validation = yup.object().shape({
  estado: yup.number().required("Campo requerido"),
  options: yup.array().when("estado", {
    is: 10,
    then: yup.array().min(1, "Seleccione una opcion"),
  }),
  /* TODO verificar el feedback */
  // options: yup.array().min(1, "Seleccione una opcion"),
  // requisito: yup.object().shape({
  //   feedBackTexto: yup.string().required("Campo requerido"),
  // }),
});
