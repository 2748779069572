import {
  Box,
  Button,
  Flex,
  UseDisclosureProps,
  useToast,
} from "@chakra-ui/react";
import { usePractica } from "../../../Hooks/Requisitos/Practicas/usePractica";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";
import { REQUISITO_ENTREGADO } from "./RequisitoRow";

interface Props {
  requisito: Requisito;
}

export const Detalle = ({ requisito, onClose }: Props & UseDisclosureProps) => {
  const { loading, handleSubmit } = usePractica(requisito);
  const toast = useToast();

  const onSubmit = async () => {
    try {
      await handleSubmit();
      toast({
        title: "Revisado",
        description: "Práctica Revisada",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
      onClose && onClose();
    } catch (error) {
      toast({
        title: "Hubo un error",
        description: "No se pudo revisar la Práctica",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  return (
    <Box py={5}>
      <div dangerouslySetInnerHTML={{ __html: requisito.descripcion }} />

      {parseInt(requisito.estado.id) === REQUISITO_ENTREGADO && (
        <Flex justifyContent={"end"} mt={5}>
          <Button colorScheme={"blue"} isLoading={loading} onClick={onSubmit}>
            Confirmar
          </Button>
        </Flex>
      )}
    </Box>
  );
};
