import React, { useState, useEffect } from "react";
import axonApi from "../../../Api/axonApi";
import { ResumenAlumnos } from "../../../Interfaces/formacionesInterface";


interface Props {
    idAula: String;
}

const ResumenAlumnosEstados: React.FC<Props> = ({ idAula }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataAlumnos, setDataAlumnos] = useState<any>({});
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { data } = await axonApi.get<ResumenAlumnos>(`/academico/aulas/${idAula}/alumnos/estado`);
                setDataAlumnos(data);
                setIsLoading(false);
            } catch (error: any) {
                console.log(error.response);
                setErrorMsg("No pudimos obtener las estadísticas del aula.");
                setIsLoading(false);
            }
        };

        fetchData();

    }, [idAula]);

    return (
        <div className="">
            {isLoading ? (
                <div>Cargando estadísticas...</div>
            ) : errorMsg ? (
                <div>{errorMsg}</div>
            ) : (
                <>
                    <div>
                        <b>Total:</b>{" "}
                        <span>
                            {dataAlumnos.totalInicial}
                        </span>
                    </div>
                    <div>
                        <b>Regular:</b>{" "}
                        <span>{dataAlumnos.regular}</span>
                    </div>
                    <div>
                        <b>Cambio de Cursada:</b>{" "}
                        <span>{dataAlumnos.ccursada}</span>
                    </div>
                    <div>
                        <b>Abandono:</b>{" "}
                        <span>{dataAlumnos.abandono}</span>
                    </div>
                    <div>
                        <b>Porcentaje de abandono:</b>{" "}
                        <span>{dataAlumnos.porcentajeAbandono} {"%"} </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default ResumenAlumnosEstados;