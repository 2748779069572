import { Box, Divider, Text } from "@chakra-ui/react";

type Props = {
  marcadores: string[];
};

export const Marcadores = ({ marcadores }: Props) => {
  return (
    <Box pt={5}>
      <Text as={"b"}>Marcadores</Text>
      {marcadores &&
        marcadores.map((marcador, key) => (
          <Box key={key} my={5}>
            <Text mb={4}>{marcador}</Text>
            <Divider />
          </Box>
        ))}
    </Box>
  );
};
