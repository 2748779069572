import styled from 'styled-components'
import {
  AccordionItem,
  AccordionButton as ChakraAccordionButton,
  AccordionPanel as ChakraAccordionPanel,
} from '@chakra-ui/react'

export const AccordionOption = styled(AccordionItem)`
  color: ${(props) => props.theme.menuOption.color};
  background-color: ${(props) => props.theme.menuOption.backgroundColor};

  border-radius: ${(props) => props.theme.menuOption.borderRadius};
  border: none;

  &.active {
    color: #40a8ff;
    background-color: #194671;
  }
`
export const AccordionButton = styled(ChakraAccordionButton)`
  display: flex;
  justify-content: space-between;

  &:focus {
    box-shadow: none !important; // !important para sobreescribir el estilo default de chakra
  }
`
export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`

export const AccordionPanel = styled(ChakraAccordionPanel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  color: #fff;
  background-color: #ffffff47;
  border-radius: 0 0 10px 10px;
`
