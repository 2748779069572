import { Accordion } from "@chakra-ui/react";
import { Competencia } from "../../../../Interfaces/requisitos/observadosInterface";
import { CompetenciaItem } from "./CompetenciaItem";

type Props = {
  competencias: Competencia[];
};

export const Competencias = ({ competencias }: Props) => {
  return (
    competencias && (
      <Accordion backgroundColor={"white"} allowToggle>
        {competencias.map((competencia, key) => (
          <CompetenciaItem key={key} index={key} competencia={competencia} />
        ))}
      </Accordion>
    )
  );
};
