import { useContext, useState } from "react";
import axonApi from "../../../Api/axonApi";
import { RequisitosContext } from "../../../Context/requisitos/RequisitosContext";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";

const REQUISITO_APROBADO = 2;

export const usePractica = (requisito: Requisito) => {
  const { updateRequisito } = useContext(RequisitosContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axonApi.put(
        `/master/profesores/requisitos/practicas/${requisito.id}`,
        {
          alumno: requisito.alumno,
          estado: 2,
          requisito: {
            nombre: requisito.titulo,
            feedBackTexto: "",
          },
        }
      );
      const newRequisito = {
        ...requisito,
        estado: { ...requisito.estado, id: REQUISITO_APROBADO.toString() },
      };
      updateRequisito(requisito.id, newRequisito);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { loading, handleSubmit };
};
