import { Flex, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { MainBox } from "../Layouts/MainBox";
import { MainTitle } from "../Layouts/MainTitle";
import { FormContainer, FormField } from "../Layouts/Form";
import { validation } from "./validation";
import { usePostulacion } from "../../Hooks/PostulacionStaff/usePostulacion";

export const PostulacionFormPage = () => {
  const { idFormulario } = useParams();
  const { isLoading, preguntas, handleSubmit } = usePostulacion(idFormulario);

  return (
    <>
      <MainTitle title="Completa el formulario" />

      <MainBox>
        <FormContainer
          validationSchema={validation}
          initialValues={{ pregunta1: "", pregunta7: "" }}
          handleSubmit={(values) => handleSubmit(values)}
        >
          <Stack gap={3}>
            <FormField
              name="pregunta1"
              inputType="radio"
              label={preguntas["pregunta1"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta2"
              inputType="radio"
              label={preguntas["pregunta2"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta3"
              inputType="radio"
              label={preguntas["pregunta3"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta4"
              inputType="radio"
              label={preguntas["pregunta4"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta5"
              inputType="radio"
              label={preguntas["pregunta5"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta6"
              inputType="radio"
              label={preguntas["pregunta6"]}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
            />
            <FormField
              name="pregunta7"
              inputType="textarea"
              label={preguntas["pregunta7"]}
              cols={30}
              rows={10}
              resize={"none"}
            />
            <FormField
              type={"submit"}
              inputType={"submit"}
              placeholder={"Enviar"}
              colorScheme={"blue"}
              justifyContent={"end"}
              isLoading={isLoading}
            />
          </Stack>
          <Flex justifyContent={""}></Flex>
        </FormContainer>
      </MainBox>
    </>
  );
};
