import { HStack, Icon, Td, Tooltip, Tr } from "@chakra-ui/react";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { OrdenInterface } from "../../../../Interfaces/ordenesInterface";
import { Carga } from "./Carga";
import { WarningIcon } from "@chakra-ui/icons";

type Props = {
  orden: OrdenInterface;
};

export const Orden = ({ orden }: Props) => {
  return (
    <Tr>
      <Td>{orden.idOP}</Td>
      <Td>
        {moment(orden.periodo.slice(0, orden.periodo.length - 3)).format(
          "MM-YYYY"
        )}
      </Td>
      <Td>
        {orden.fechaFactura
          ? moment(orden.fechaFactura).add(45, "days").format("DD-MM-YYYY")
          : "Sin fecha"}
      </Td>
      <Td>{orden.nombre}</Td>
      <Td>
        <HStack>
          <Tooltip label={"Orden de pago"}>
            <a href={orden.pdf} target={"_blank"} rel="noreferrer">
              <FaRegFilePdf color="red" fontSize={"25px"} />
            </a>
          </Tooltip>
          {orden.facturaDocente.length ? (
            <Tooltip label={"Factura docente"}>
              <a href={orden.facturaDocente} target={"_blank"} rel="noreferrer">
                <Icon as={GrDocumentPdf} fontSize={"25px"} marginLeft={"8px"} />
              </a>
            </Tooltip>
          ) : orden.estado !== "5" ? (
            <Carga orden={orden} />
          ) : (
            <WarningIcon
              color="red"
              fontSize={"25px"}
              style={{ marginLeft: "1rem" }}
            />
          )}
        </HStack>
      </Td>
    </Tr>
  );
};
