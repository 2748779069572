import axios from "axios";
import Cookies from "js-cookie";
const token = Cookies.get("token");

const baseUrl =
  window.location.hostname === "profesores.axontraining.com"
    ? "https://api.axontraining.com"
    : "https://api-dev.axontraining.com";

const axonApi = axios.create({
  baseURL: baseUrl,
});

if (token) axonApi.defaults.headers.common["Authorization"] = "Bearer " + token;

export default axonApi;
