import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { ClasesInterface } from "../../../Interfaces/formacionesInterface";
import { Clase } from "./Clase";

type Props = {
  clases: ClasesInterface[];
};

export const Clases = ({ clases }: Props) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Titulo</Th>
          <Th>Fecha</Th>
          <Th>Asistentes</Th>
          <Th>Ver Clase</Th>
        </Tr>
      </Thead>
      <Tbody>
        {clases &&
          clases.map((clase) => <Clase key={clase.id} clase={clase} />)}
      </Tbody>
    </Table>
  );
};
