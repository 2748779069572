import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { ObservadoContext } from "../../../Context/requisitos/observados/ObservadoContext";
import { MainTitle } from "../../Layouts/MainTitle";
import { SpinnerAxn } from "../../Layouts/SpinnerAxn";
import { Cabecera } from "./Observado/Cabecera";
import { Correccion } from "./Observado/Correccion";
import { Entrega } from "./Observado/Entrega";

export const Observado = () => {
  const { isLoading, corrigeExamen } = useContext(ObservadoContext);

  return (
    <>
      <MainTitle title="Revisión de Observado" />

      {isLoading ? (
        <SpinnerAxn />
      ) : (
        <Box py={5}>
          <Cabecera />

          {corrigeExamen ? <Correccion /> : <Entrega />}
        </Box>
      )}
    </>
  );
};
