import { ReactNode } from "react";
import { Form, Formik } from "formik";

type Props = {
  children?: JSX.Element | JSX.Element[];
  initialValues: { [k: string]: any };
  validationSchema: object;
  handleSubmit: (values: { [k: string]: any }) => void;
  render?: (values: any) => ReactNode;
};

export const FormContainer = ({
  children,
  initialValues,
  validationSchema,
  handleSubmit,
  render,
}: Props) => {
  return (
    <Formik
      validateOnMount={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={true}
    >
      {({ values }) => {
        return <Form>{render ? render(values) : children}</Form>;
      }}
    </Formik>
  );
};
