import { useContext, useState } from "react";
import axonApi from "../../../Api/axonApi";
import { RequisitosContext } from "../../../Context/requisitos/RequisitosContext";
import { Requisito } from "../../../Interfaces/requisitos/requisitosInterface";

export const useAcuerdo = (idAcuerdo: string) => {
  const { questions, updateRequisito } = useContext(RequisitosContext);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (
    values: { [k: string]: any },
    requisito: Requisito
  ) => {
    setLoading(true);
    let form = { ...values };
    const checked = questions.filter((question) =>
      form.options.includes(question.id)
    );

    form = { ...form, checked };

    delete form.options;

    try {
      const { data } = await axonApi.put<{ url: string | null }>(
        `/master/profesores/requisitos/acuerdos/${idAcuerdo}`,
        form
      );
      const newRequisito = {
        ...requisito,
        PDF: data.url,
        estado: { ...form.estado, id: form.estado },
      };

      updateRequisito(idAcuerdo, newRequisito);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw error.response.data.error;
    }
  };

  return { loading, handleSubmit };
};
