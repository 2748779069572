import { useContext } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { ObservadoContext } from "../../../../Context/requisitos/observados/ObservadoContext";
import { MainBox } from "../../../Layouts/MainBox";
import { useParams, Link } from "react-router-dom";

export const Cabecera = () => {
  const { observado } = useContext(ObservadoContext);
  const { idObservado } = useParams();
  const historialObservados = observado.historialObservados.filter(
    (historial) => historial.id !== idObservado
  );
  return (
    <>
      <MainBox gap={2}>
        <Text mb={3}>
          <b>Alumno:</b> {observado.alumno.nombre} {observado.alumno.apellido}
        </Text>
        <Text mb={3}>
          <b>Título:</b> {observado.titulo}
        </Text>
        <Text mb={3}>
          <b>Descripción:</b> {observado.descripcion}
        </Text>
        <Text mb={3}>
          <b>Método de Revisión:</b> {observado.metodo}
        </Text>
        <Text mb={3}>
          <b>Archivo:</b> {observado.audio.archivo}
        </Text>
        <Flex justifyContent={"end"}>
          <a
            href={observado.audio.ubicacion}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Button colorScheme={"blue"}>
              Escuchar Coaching Observado Completo
            </Button>
          </a>
        </Flex>
      </MainBox>

      {historialObservados && historialObservados.length ? (
        <MainBox>
          <Text as={"b"}>Historial de Entregas</Text>

          <Flex gap={2} mt={3}>
            {historialObservados.map((entrega) => (
              <Link
                key={entrega.id}
                to={`/requisitos/observados/${entrega.id}`}
              >
                <Button colorScheme={"blue"}>
                  Fecha entrega: {entrega.fechaSubida}
                </Button>
              </Link>
            ))}
          </Flex>
        </MainBox>
      ) : null}
    </>
  );
};
