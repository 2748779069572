import { useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import axonApi from "../../Api/axonApi";
import { PostulacionesContext } from "../../Context/docentes/postulaciones/postulacionesContext";
import { SessionContext } from "../../Context/session/SessionContext";
import {
  Postulacion,
  Postulado,
} from "../../Interfaces/postulacionesInterface";

export const usePostulacion = (postulacion: Postulacion) => {
  const { session } = useContext(SessionContext);
  const { updatePostulacionData } = useContext(PostulacionesContext);
  const [loading, setLoading] = useState(false);
  const cargo = session?.sector.id === "11" ? 1 : 2;
  const toast = useToast();

  const addPostulacion = async () => {
    setLoading(true);
    try {
      const { data } = await axonApi.post<Postulado>(
        `/master/profesores/postulaciones`,
        {
          curso: postulacion,
          cargo,
        }
      );

      updatePostulacionData({ ...postulacion, postulado: data });
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: `No se pudo postular a ${postulacion.alias}`,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const deletePostulacion = async () => {
    setLoading(true);
    try {
      await axonApi.delete(
        `/master/profesores/postulaciones/${postulacion.postulado?.id}`
      );
      updatePostulacionData({ ...postulacion, postulado: null });
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: `No se pudo eliminar la postulacion a ${postulacion.alias}`,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
      setLoading(false);
    }
  };

  return {
    loading,
    addPostulacion,
    deletePostulacion,
  };
};
