import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Tr, Td, HStack } from "@chakra-ui/react";
import { FaRegFilePdf } from "react-icons/fa";
import { Materiales } from "../../Interfaces/materiales/materialesInterface";
import ModalAxon from "../Layouts/Modal/ModalAxon";
import { ModalDelete } from "./ModalDelete";
import { ModalMaterial } from "./ModalMaterial";

interface Props {
  material: Materiales;
  idCurso: string;
  idClase: string;
}

export const MaterialItem = ({ material, idCurso, idClase }: Props) => {
  return (
    <Tr>
      <Td>
        <a href={material.urlArchivo}>
          <FaRegFilePdf color="red" fontSize={"20px"} />
        </a>
      </Td>
      <Td>{material.descripcion}</Td>
      <Td>
        <HStack>
          <ModalAxon
            trigger={
              <DeleteIcon
                color={"red.500"}
                cursor={"pointer"}
                fontSize={"20px"}
                fontWeight={"bold"}
              />
            }
            render={() => (
              <ModalDelete
                material={material}
                idClase={idClase}
                idCursoAca={idCurso}
              />
            )}
            title={`Eliminar el material perteneciente al curso: ${idCurso}`}
          />
          <ModalAxon
            trigger={
              <EditIcon
                color={"blue.500"}
                cursor={"pointer"}
                fontSize={"20px"}
                fontWeight={"bold"}
              />
            }
            render={() => (
              <ModalMaterial
                material={material}
                idClase={idClase}
                idCursoAca={idCurso}
              />
            )}
            title={`Editar el material perteneciente al curso: ${idCurso}`}
          />
        </HStack>
      </Td>
    </Tr>
  );
};
