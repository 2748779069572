import React from 'react'
import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { Laboral } from '../../../Interfaces/perfilAcademicoInterface';
type Props = {
  laboral: Laboral;
  position: number;
  handleInformation: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "laboral",
    key: number
  ) => void;
  deleteItem: (type: "laboral", key: number) => void;
};

export const LaboralItem = ({
  laboral,
  position,
  handleInformation,
  deleteItem,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteEducation = async () => {
    await deleteItem("laboral", position);
    onClose();
  };
  return (
    <Tr>
      <Td>
        <Input
          name="empresa"
          value={laboral.empresa}
          onChange={(e) => handleInformation(e, "laboral", position)}
        />
      </Td>
      <Td>
        <Input
          name="puesto"
          value={laboral.puesto}
          onChange={(e) => handleInformation(e, "laboral", position)}
        />
      </Td>
      <Td>
        <Input
          type={"number"}
          name="fecha"
          value={laboral.fecha}
          onChange={(e) => handleInformation(e, "laboral", position)}
        />
      </Td>
      <Td>
        <IconButton
          size={"sm"}
          colorScheme={"red"}
          aria-label="Borrar"
          icon={<FaTrash />}
          onClick={() => onOpen()}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Eliminar experiencia laboral</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Se eliminara su experiencia laboral</ModalBody>
            <ModalFooter>
              <Button colorScheme={"red"} onClick={() => deleteEducation()}>
                Eliminar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
}
