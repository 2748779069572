import { Postulacion } from "../../../Interfaces/postulacionesInterface";
import { PostulacionesInterface } from "./postulacionesContext";

export type ActionType =
  | { type: "setPostulaciones"; payload: Postulacion[] }
  | { type: "updatePostulacion"; payload: Postulacion[] };

export const postulacionesReducer = (
  state: PostulacionesInterface,
  action: ActionType
) => {
  switch (action.type) {
    case "setPostulaciones":
      return { ...state, postulaciones: action.payload, isLoading: false };
    case 'updatePostulacion': 
      return { ...state, postulaciones: action.payload }
    default:
      return { ...state };
  }
};
