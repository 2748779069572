import { MaterialesProvider } from "../../Context/materiales/materialesContext";
import { MainTitle } from "../Layouts/MainTitle";
import { AulasList } from "./AulasList";

export const MaterialesPage = () => {
  return (
    <MaterialesProvider>
      <MainTitle title="Materiales" />

      <AulasList />
    </MaterialesProvider>
  );
};
