import { createContext, useEffect, useReducer } from "react";
import axonApi from "../../../Api/axonApi";
import {
  Postulacion,
  PostulacionInterface,
} from "../../../Interfaces/postulacionesInterface";
import { postulacionesReducer } from "./postulacionesReducer";

export interface PostulacionesInterface {
  postulaciones: Postulacion[];
  isLoading: boolean;
  updatePostulacionData: (postulacion: Postulacion) => void;
}

export const PostulacionesContext = createContext({} as PostulacionesInterface);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const initialValues = {
  postulaciones: [],
  isLoading: true,
  updatePostulacionData: () => {},
};

export const PostulacionesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(postulacionesReducer, initialValues);

  useEffect(() => {
    (async () => {
      const { data } = await axonApi.get<PostulacionInterface>(
        "/usuarios/profesores/postulaciones"
      );
      dispatch({ type: "setPostulaciones", payload: data.postulaciones });
    })();
  }, []);

  const updatePostulacionData = (postulacionUpdated: Postulacion) => {
    const postulacionesCopy = state.postulaciones.map(
      (postulacion: Postulacion) =>
        postulacion.id === postulacionUpdated.id
          ? postulacionUpdated
          : postulacion
    );
    dispatch({ type: "updatePostulacion", payload: postulacionesCopy });
  };

  return (
    <PostulacionesContext.Provider value={{ ...state, updatePostulacionData }}>
      {children}
    </PostulacionesContext.Provider>
  );
};
