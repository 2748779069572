import { Box, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useContext } from "react";
import { OrdenesContext } from "../../../../Context/docentes/ordenesDePago/ordenesContext";
import { SpinnerAxn } from "../../../Layouts/SpinnerAxn";
import { FormBuscador } from "../FormBuscador";
import { Orden } from "./Orden";

export const Buscador = () => {
  const { isLoading, ordenes, getOrdenes } = useContext(OrdenesContext);

  const handleSubmit = async (values: { name: string; date: string }) => {
    try {
      await getOrdenes(values.date);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  return (
    <>
      <Box backgroundColor={"white"} p={5}>
        <FormBuscador handleSubmit={handleSubmit} btnText={"Buscar"} />
      </Box>

      {isLoading ? (
        <SpinnerAxn />
      ) : (
        ordenes &&
        ordenes.length > 0 && (
          <Box
            backgroundColor={"white"}
            p={5}
            overflowX={"auto"}
            mt={5}
            maxWidth={"100vw"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Th>OP</Th>
                  <Th>Periodo</Th>
                  <Th>Fecha de pago</Th>
                  <Th>Estado</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                {ordenes.map((orden) => (
                  <Orden orden={orden} key={orden.idOP} />
                ))}
              </Tbody>
            </Table>
          </Box>
        )
      )}
    </>
  );
};
