import React, { useContext } from 'react'
import { MdLogout } from 'react-icons/md'

import { WrapperSidebarContext } from '../wrapperSidebarContext'
import * as S from './ProfileAndLogout.styles'

const ProfileAndLogout = () => {
  const { session, logout } = useContext(WrapperSidebarContext)

  return (
    <S.ProfileAndLogout>
      <S.Profile>
        <a
          href='https://campus.axontraining.com/perfil'
          style={{
            color: 'white',
            fontSize: '1rem',
          }}
        >
          {session?.name}
        </a>
      </S.Profile>
      <MdLogout onClick={logout} color='#FFF' cursor='pointer' size='1.5rem' style={{ margin: '1rem 0 0 1rem' }} />
    </S.ProfileAndLogout>
  )
}

export default ProfileAndLogout
