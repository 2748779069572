import { Badge, Button, useToast } from "@chakra-ui/react";
import { useState, useContext } from "react";
import axonApi from "../../Api/axonApi";
import { SingleValue } from "react-select/dist/declarations/src";
import { ClasesInterface } from "../../Interfaces/clasesInterface";
import { ClasesContext } from "../../Context/clases/ClasesContext";
import { OptionsInterface } from "../../Interfaces/interfaces";

type estadoClaseInterface = {
  [k: string]: JSX.Element;
};

export const useClase = (clase: ClasesInterface) => {
  const toast = useToast();
  const { updateClase } = useContext(ClasesContext);
  const [profesorSelected, setprofesorSelected] = useState<string>("");
  const [loadingInicioClase, setLoadingInicioClase] = useState<boolean>(false);
  const [classLink, setClassLink] = useState<string>(clase.startUrl ?? "");

  const onChangeProfesor = (profesor: SingleValue<OptionsInterface>) => {
    if (profesor) setprofesorSelected(profesor.value);
  };

  const asignarProfesor = async () => {
    try {
      await axonApi.patch(`/academico/clases/${clase.id}`, {
        idProfesor: profesorSelected,
      });
      toast({
        title: "Profesor asignado con exito",
        status: "success",
        isClosable: true,
        duration: 5000,
      });
      setprofesorSelected("");
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        isClosable: true,
        duration: 5000,
      });
      setprofesorSelected("");
    }
  };

  const iniciarClase = async () => {
    setLoadingInicioClase(true);
    const { data } = await axonApi.put(`/academico/clases/${clase.id}/status`, {
      status: "iniciada",
    });

    if (!data.clase) {
      toast({
        title: data,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoadingInicioClase(false);
    } else {
      if (data.clase.startUrl) {
        updateClase({
          ...data.clase,
          estadoInicio: { estado: "vivo", url: data.clase.startUrl },
        });
        setClassLink(data.clase.startUrl);
        window.open(data.clase.startUrl);
        toast({
          title: "Clase iniciada correctamente",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setLoadingInicioClase(false);
      } else {
        toast({
          title: "Hubo un error al unirse",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoadingInicioClase(false);
      }
    }
  };

  const unirseClase = async () => {
    setLoadingInicioClase(true);
    window.open(classLink);
    setLoadingInicioClase(false);
    // try {
    //   const { data } = await axonApi.put(`/academico/clases/${clase.id}/status`, {
    //     status: "iniciada",
    //   });
    //   if (data.clase.startUrl) {
    //     updateClase({
    //       ...data.clase,
    //       estadoInicio: { estado: "vivo", url: data.clase.startUrl },
    //     });
    //     window.open(data.clase.startUrl);
    //     setLoadingInicioClase(false);
    //   } else {
    //     setLoadingInicioClase(false);
    //     toast({
    //       title: "Hubo un error al unirse",
    //       status: "error",
    //       duration: 5000,
    //       isClosable: true,
    //     });
    //   }
    // } catch (error: any) {
    //   setLoadingInicioClase(false);
    //   toast({
    //     title: error.response.data.error,
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
  };

  const estadoClase: estadoClaseInterface = {
    "no-iniciada": <Badge>No iniciada</Badge>,
    "debe-iniciar": (
      <Button
        colorScheme={"green"}
        disabled={loadingInicioClase}
        isLoading={loadingInicioClase}
        onClick={iniciarClase}
      >
        Iniciar clase
      </Button>
    ),
    "finalizada-con-grabacion": (
      <Button colorScheme={"blue"}>
        <a href={clase.linkZoom ?? ""} target={"_blank"} rel="noreferrer">
          Ver Grabación
        </a>
      </Button>
    ),
    "finalizada-sin-grabacion": (
      <Badge colorScheme={"cyan"}>Clase finalizada</Badge>
    ),
    vivo: (
      <Button
        isLoading={loadingInicioClase}
        disabled={loadingInicioClase}
        onClick={unirseClase}
      >
        Unirse a la clase
      </Button>
    ),
  };

  return {
    classLink,
    profesorSelected,
    estadoClase,
    loadingInicioClase,
    onChangeProfesor,
    asignarProfesor,
    iniciarClase,
  };
};
