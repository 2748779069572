import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Acuerdo } from "../../../../Interfaces/ordenesInterface";

type Props = {
  acuerdos: Acuerdo[];
};

export const CorreccionesList = ({ acuerdos }: Props) => {
  return (
    <>
      <Heading as={"h2"} size={"lg"} my={5}>
        Correcciones
      </Heading>

      {acuerdos && (
        <Table variant={"simple"}>
          <Thead>
            <Tr>
              <Th>Fecha</Th>
              <Th>Alumno</Th>
              <Th>Título</Th>
            </Tr>
          </Thead>
          <Tbody>
            {acuerdos.map((correccion) => (
              <Tr key={correccion.id}>
                <Td>{correccion.fechaAcuerdo}</Td>
                <Td>
                  {correccion.nombre} - R ({correccion.revision})
                </Td>
                <Td>
                  {correccion.titulo} -{" "}
                  {correccion.metodorevision === "3" ||
                  correccion.metodorevision === "8"
                    ? "Normal"
                    : "Vía Rápida"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};
