import { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { SessionContext } from "../../../Context/session/SessionContext";
import * as Yup from "yup";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { OrdenesContext } from "../../../Context/docentes/ordenesDePago/ordenesContext";

type Props = {
  handleSubmit: (values: any) => void | Promise<void>;
  btnText: string;
};

export const FormBuscador = ({ handleSubmit, btnText }: Props) => {
  const { session } = useContext(SessionContext);
  const { loadingGenerateOrderForm, isLoading } = useContext(OrdenesContext);
  const validation = Yup.object().shape({
    date: Yup.string().required("Campo requerido"),
  });

  return (
    <Formik
      initialValues={{
        name: `${session?.nombre} ${session?.apellido}`,
        date: "",
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
      validateOnChange={true}
    >
      {({ values, isValid }) => (
        <Form>
          <Stack direction={["column", "row"]}>
            <FormControl>
              <FormLabel>Profesor</FormLabel>
              <Field as={Input} isDisabled name={"name"} />
            </FormControl>

            <FormControl>
              <FormLabel>Seleccione un periodo</FormLabel>
              <Field name={"date"} as={Input} type={"month"} />
            </FormControl>
          </Stack>

          <Flex justifyContent={"end"}>
            <Button
              type="submit"
              colorScheme={"blue"}
              isDisabled={
                (isValid &&
                  Object.values(values).some((value) => value.length === 0)) ||
                !isValid ||
                isLoading
              }
              mt={5}
              isLoading={loadingGenerateOrderForm}
            >
              {btnText}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
